import { Table } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  query_group_wise_report,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import _orderBy from "lodash/orderBy";
import PrintHeader from "../../../common/print_header";
import { IS_TABLE_SCROLLABLE } from "../../../../../helpers/constants";

const GroupWiseReportList = (props) => {
  const { selected_language, report_date, componentRef, yesterday_date } =
    props;
  const dispatch = useDispatch();
  const { items: report_list, loading: group_wise_report_loading } =
    useDynamicSelector("get_district_group_wise_count");

  const last_year_total = report_list?.reduce(
    (acc, item) => acc + (item?.previous_season_enrolled_count || 0),
    0
  );

  const total_yesterday = report_list?.reduce(
    (acc, item) => acc + (item?.till_date_devotees || 0),
    0
  );

  const total_today = report_list?.reduce(
    (acc, item) => acc + (item?.selected_date_devotees || 0),
    0
  );

  const total = report_list?.reduce(
    (acc, item) => acc + (item?.total_devotees || 0),
    0
  );

 

  const { t } = useTranslation();
  const columns = [
    {
      title: selected_language === "Tamil" ? "மாவட்ட குழு" : "Districts",
      dataIndex:
        selected_language === "Tamil"
          ? "district_group_tamil_name"
          : "district_group_name",
      width: "250px",
    },
    {
      title:
        selected_language === "Tamil"
          ? "கடந்த வருட மொத்தம் "
          : "Last Year Total",
      width: "200px",
      align: "right",
      dataIndex: "previous_season_enrolled_count",
    },
    {
      title:
        selected_language === "Tamil"
          ? `நேற்று வரை 
        ${yesterday_date ? yesterday_date : ""}`
          : `Till Yesterday
          ${yesterday_date ? yesterday_date : ""}`,
      width: "250px",
      align: "right",
      dataIndex: "till_date_devotees",
    },
    {
      title:
        selected_language === "Tamil"
          ? `இன்று         
           ${report_date?.label}`
          : `Till Today
          ${report_date?.label}`,

      width: "250px",
      align: "right",
      dataIndex: "selected_date_devotees",
    },
    {
      title: selected_language === "Tamil" ? "இந்த வருட மொத்தம் " : "Total",
      width: "250px",
      align: "right",
      dataIndex: "total_devotees",
    },
    {
      title: selected_language === "Tamil" ? "வித்தியாசம்" : "Difference",
      width: "250px",
      align: "right",
      render: (text, record) => {
        const difference =
          (record?.total_devotees || 0) -
          (record?.previous_season_enrolled_count || 0);
        return difference;
      },
    },
  ];

  return (
    <div>
      <Table
        data-name-table="district-wise-report"
        className="group-wise-report screen-table"
        columns={columns}
        ref={componentRef}
        dataSource={report_list}
        loading={group_wise_report_loading}
        style={{ width: "96%", margin: "auto" }}
        pagination={false}
        scroll={{
          x: true,
          y: IS_TABLE_SCROLLABLE ? null : 220,
        }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <text style={{ fontWeight: "bold", paddingLeft: "50px" }}>
                  {selected_language === "Tamil" ? "மொத்தம்" : "Total"}
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} className="aligned-cell">
                <text style={{ fontWeight: "bold" }}>
                  {report_list
                    ? report_list.reduce(
                        (sum, record) =>
                          sum + (record?.previous_season_enrolled_count || 0),
                        0
                      )
                    : 0}
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} className="aligned-cell">
                <text style={{ fontWeight: "bold" }}>
                  {report_list
                    ? report_list.reduce(
                        (sum, record) =>
                          sum + (record?.till_date_devotees || 0),
                        0
                      )
                    : 0}
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} className="aligned-cell">
                <text style={{ fontWeight: "bold" }}>
                  {report_list
                    ? report_list.reduce(
                        (sum, record) =>
                          sum + (record?.selected_date_devotees || 0),
                        0
                      )
                    : 0}
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} className="aligned-cell">
                <text style={{ fontWeight: "bold" }}>
                  {report_list
                    ? report_list.reduce(
                        (sum, record) => sum + (record?.total_devotees || 0),
                        0
                      )
                    : 0}
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} className="aligned-cell">
      <text style={{ fontWeight: "bold" }}>
        {report_list
          ? (
              report_list.reduce(
                (sum, record) => sum + (record?.total_devotees || 0),
                0
              ) -
              report_list.reduce(
                (sum, record) => sum + (record?.previous_season_enrolled_count || 0),
                0
              )
            )
          : 0}
      </text>
    </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
      <div className="print-header" style={{ padding: "15px" }}>
        <PrintHeader title={"DISTRICT GROUP WISE REPORT"} />

        <h4
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          DISTRICT GROUP WISE REPORT
        </h4>
        <p style={{ marginLeft: "300px" }}>{report_date?.label}</p>
      </div>

      <div style={{ padding: "10px" }}>
        <table
          className="table table-bordered print-table"
          style={{ padding: "10px", width: "100%", marginTop: "10px" }}
        >
          <thead style={{ width: "100%" }}>
            <tr>
              {columns.map((column) => (
                <th
                  className="header-style"
                  style={{ width: "100px" }}
                  key={column.key}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {report_list?.map((report, index) => (
              <tr key={index}>
                <td className="body-style text-left-align">
                  {selected_language === "Tamil"
                    ? report?.district_group_tamil_name
                    : report?.district_group_name}
                </td>
                <td className="body-style " style={{ textAlign: "right" }}>
                  {report?.previous_season_enrolled_count}
                </td>
                <td className="body-style " style={{ textAlign: "right" }}>
                  {report?.till_date_devotees}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.selected_date_devotees}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.total_devotees}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {(report?.total_devotees || 0) -
                    (report?.previous_season_enrolled_count || 0)}
                </td>
              </tr>
            ))}
            <tr>
              <td className="body-style text-left-align">
                <b>{selected_language === "Tamil" ? "மொத்தம்" : "Total"}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b> {last_year_total}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b> {total_yesterday}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{total_today}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{total}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{total-last_year_total}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <style>
        {`
    @media screen {
      .print-table {
        display: none;
      }
      .print-header {
        display: none !important;
      }
    }

    @media print {
      .screen-table {
        display: none !important;
      }
      .print-header {
        display: block !important;
      }

      @media print {
        @page {
          size: A4;
          margin: 1cm;
        }
  
        body::before {
           content: counter(page);
           top: 0;
          right: 1cm;
        }
      }
    }
  `}
      </style>
    </div>
  );
};

export default GroupWiseReportList;
