import { Typography } from "antd";
import React from "react";
import { ROUTES } from "../../../../routes/my_routes";
import { navigate } from "../../../../../helpers/functions";

const TermsAndConditions = () => {
  const handle_click = () => {
    navigate(ROUTES.TERMS_OF_SERVICES);
  };
  const handle_privacy_policy = () => {
    navigate(ROUTES.PRIVACY_POLICY);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        gap: "60px",
      }}
    >
      <Typography
        style={{
          textDecoration: "underline",
          marginTop: "8px",
          cursor: "pointer",
        }}
        onClick={handle_privacy_policy}
      >
        Privacy Policy
      </Typography>
      <Typography
        style={{
          textDecoration: "underline",
          marginTop: "8px",
          cursor: "pointer",
        }}
        onClick={handle_click}
      >
        Terms and Conditions
      </Typography>
    </div>
  );
};

export default TermsAndConditions;
