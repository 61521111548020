import { Col, Row } from "antd";
import React, { useState } from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import GroupWiseEntryForm from "../components/system_report/group_wise_entry_report/group_wise_entry_form";

const GroupWiseEntryReport = () => {
  const { t } = useTranslation();
  const [selected_date,set_selected_date]=useState()
  return (
    <Col>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row style={{ marginLeft: "10px" }}>
          <CustomPageTitle title={t("group_wise_entry_report")} />
        </Row>
      </Row>
      <div style={{ marginTop: "20px" }}>
      <GroupWiseEntryForm set_selected_date={set_selected_date}/>
      </div>
    </Col>
  );
};

export default GroupWiseEntryReport;
