import { Form, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../../services/redux";

const DateWiseReportMandram = ({
  set_mandram_item,
  set_initial_values,
  set_mandram_id,
}) => {
  const { t } = useTranslation();
  const { items: date_wise_report_mandram_list } = useDynamicSelector(
    "date_wise_report_mandram_list"
  );
  // const options = mandram_items || [];

  const on_change = (value) => {
    set_mandram_item(value);
  };

  const custom_filter_option = (input, option) => {
    const input_value = input.toLowerCase();
    const option_label = option.props.children.toLowerCase();
    return option_label.indexOf(input_value) === 0;
  };
  
  // options.map((a) => a.id);
  return (
    <div>
      <Form.Item label={"Mandram(VSG)"} name="mandram_vsg">
        <Select
          mode="multiple"
          placeholder={t("please_select")}
          onChange={on_change}
          allowClear
          showSearch
          filterOption={custom_filter_option}
        >
          {date_wise_report_mandram_list?.map((mandram) => (
            <Select.Option key={mandram.id} value={mandram.id}>
              {`${mandram?.code}/${mandram?.name}`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default DateWiseReportMandram;
