import React from "react";
import { Typography } from "antd";

const FastingRituals = () => {
  const { Text } = Typography;
  return (
    <div style={{ alignItems: "center", padding: "20px" }}>
      <div>
        <Text
          style={{
            color: "black",
            textAlign: "justify",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          விரத நாட்களில் வழிபாட்டு முறைகள்:
        </Text>
      </div>
      <ul>
        <div style={{ marginTop: "10px" }}>
          <li>
            <Text
              style={{
                color: "black",
                textAlign: "justify",
                fontSize: "14px",
              }}
            >
              அன்னை தன் அருள்வாக்கில் அருளிய வண்ணம், ஐவர் குழுவாகவோ, மூவர்
              குழுவாகவோ குடும்பங்கள் தமக்குள் சேர்ந்து கொண்டு, ஒவ்வொருவர்
              வீட்டிற்கும், மற்றவர்கள் சென்று அவர்கள் வீட்டிற்குத் திருஷ்டி
              கழித்துவிட்டுக் கூட்டு வழிபாடு செய்துவிட்டு அவர்கள் வீட்டில்
              அளிக்கும் உணவை உண்ண வேண்டும்.{" "}
            </Text>
          </li>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                மன்றத்தில் இருமுடித் தொண்டர்கள் பொறுப்பேற்று இருமுடிப்பைகளை
                மன்றத்திலேயே பாதுகாக்க வேண்டும். பாதுகாப்பு இல்லையெனக்கருதி
                இடமாற்றம் செய்யக்கூடாது.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                சக்திமாலை அணிந்திருக்கும் காலங்களில், உறவினர்,
                பங்காளிகள் வீட்டில் அசம்பாவித காரியங்கள் ஏற்படுமானால் தீட்டு
                எனக்கருதிமாலையை கழற்றத் தேவையில்லை. மாலையுடனேயே மேற்படிக்
                காரியங்களில் பங்கேற்றுத் தொண்டாற்றலாம்.
              </Text>
            </li>
          </div>

          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                சக்திமாலை அணிபவர்கள் விரத நாட்களில் ஆடம்பரமின்றி. மிகக்குறைவான
                அவசியமான நகைகளை மட்டுமே அணிதல் வேண்டும்.
              </Text>
            </li>
          </div>

          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                சக்தி மலை அணிந்து விரதமிருந்து சித்தர் பீடத்தில் இருமுடி
                செலுத்திவிட்டுத் தங்கள் இல்லம் திரும்பி மாலையைக் கழற்றி,
                ஆன்மிககுரு, அன்னை அல்லதுமற்ற தெய்வங்களின் திருவுருவப் படங்களில்
                மாட்டி வைக்கும்வரைக் கண்டிப்பாக வெற்றிலை, பாக்கு உட்பட
                எந்தவிதமான இலாகிரிப் பொருட்களும் உபயோகிக்கக் கூடாது.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                விரத காலங்களில் அந்தந்தமாலை எண்ணிக்கைக்கு உரிய சிவப்பு அல்லது
                மஞ்சள் நிற உடைகளுடனேயே கண்டிப்பாகஇருக்க வேண்டும். மிதியடிகள்
                தவிர்க்கப்பட வேண்டும்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                மஞ்சளாடையில் இருப்பவர்களுக்குச் செவ்வாடைச்சக்தி குருவாகமாட்டார்.
                செவ்வாடைகுருவாக இருக்கும் மன்றத்தில், மஞ்சளாடை அணிந்தவர் மாலை
                போட்டுக் கொள்ளவந்தால், மாலை அணிந்தபின் அவரே குரு ஆவார்.
                மன்றத்தில் உள்ள மாலையணிந்த மஞ்சள் உடை அனைவருமே குரு ஆவார்.
              </Text>
            </li>
          </div>
        </div>
      </ul>
    </div>
  );
};
export default FastingRituals;
