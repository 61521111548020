import { gql } from "@apollo/client";

export const om_sakthi_report_query = gql`
  query get_om_sakthi_summary($filter: om_sakthi_summary_input) {
    get_om_sakthi_summary(filter: $filter) {
      items {
        total_cash_amount
        total_dd_amount
        total_card_amount
        vip_total_devotees
        total_upi_amount
        total_devotees
        total_amount
        base_total_amount
        base_total_devotees
        vip_total_amount
        total_online_amount
         total_vc_arrived_count
      total_vc_amount
      total_corpus_amount
      total_corpus_arrived_count
        
      }
      error {
        status_code
        message
      }
      pagination {
        page_number
        page_number
        page_limit
      }
    }
  }
`;

export const om_sakthi_report_date_query = gql`
  query get_season_date_slot_list {
    om_sakthi_report_date: get_season_date_slot_list {
      items {
        id
        season_id
        date
        date_string
      }
      error {
        message
        status_code
      }
      pagination {
        total_count
        page_number
        page_limit
      }
    }
  }
`;

export const query_om_sakthi_payment = gql`
  query get_om_sakthi_payment($filter: om_sakthi_summary_input) {
    get_om_sakthi_payment(filter: $filter) {
      items {
        payment_mode
        dd_reference
        pos_receipt
        amount
        arrived_count
        dd_datetime
        receipt_number
        ref_number
      }
      error {
        status_code
        message
      }
      pagination {
        total_count
        page_number
        page_limit
      }
    }
  }
`;
