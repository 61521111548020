import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { district_details_query } from "../../../../services/redux/slices/graphql/graphql_district";
import { dynamicRequest, useDynamicSelector } from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const CorpusReportDistrict = (props) => {
  const { set_selected_district, set_district_name, set_value_change } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items: district_list_item = [] } = useDynamicSelector("district_list"); // Provide a fallback as an empty array

  useEffect(() => {
    get_district_list();
  }, []);

  const get_district_list = () => {
    let key = [{ key: "district_list", loading: true }];
    let query = district_details_query;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_change = (values, option) => {
    set_selected_district(values);
    set_district_name(option?.label);
    set_value_change(true);
  };

  const custom_filter_option = (input, option) => {
    const inputValue = input.toLowerCase();
    const optionLabel = option.props.children.toLowerCase();
    return optionLabel.indexOf(inputValue) === 0;
  };

  // Add "All Districts" as the first item in the options
  const district_options = [
    { id: "all_district", name: "All Districts" },
    ...district_list_item, // Ensure it's always an array before spreading
  ];

  return (
    <Form.Item
      name="district_d"
      label={t("district")}
      rules={[{ required: true, message: "Please Select District" }]}
    >
      <Select
        showSearch
        allowClear
        filter
        filterOption={custom_filter_option}
        placeholder="Select District"
        onChange={handle_change}
      >
        {district_options.map((option, index) => (
          <Select.Option key={index} value={option.id} label={option.name}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default CorpusReportDistrict;
