import { count_format } from "@src/helpers/constants";
import { Col, Table } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPrint } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { retrieveItem } from "../../../../helpers/functions";
import { formatStatus } from "../../../../helpers/constants";
import { useDynamicSelector } from "../../../../services/redux";
import BookingHistoryCancelBookingModal from "../booking_history_cancel_booking_modal/booking_history_cancel_booking_modal";
import { startCase } from "lodash";

const MandramWiseBookingList = (props) => {
  const { t } = useTranslation();
  const [booking_cancel_modal, set_booking_cancel_modal] = useState(false);
  const [cancel_booking_details, set_cancel_booking_details] = useState({});
  const role = retrieveItem("role");
  const { items: get_booking_list, loading: get_booking_list_loading } =
    useDynamicSelector("get_booking_list");

  let columns = [
    {
      title: t("table:booking_no"),
      dataIndex: "booking_reference",
      key: "booking_reference",
      fixed: "left",
    },
    {
      title: t("table:booked"),
      // dataIndex: "confirmed_count",
      key: "confirmed_count",
      render: (record) => {
        console.log("record",record);
        
        const booked_count = record?.confirmed_count
          ? record?.confirmed_count
          : record?.count;
        return count_format(booked_count);
      },
    },
    {
      title: t("table:cancelled"),
      dataIndex: "cancelled_count",
      key: "cancelled_count",
      render: count_format,
    },
    {
      title: t("table:enrolled"),
      dataIndex: "",
      key: "enrolled",
      render: (record) => {
        const total_arrived_count = record?.enrolment_list?.reduce(
          (acc, booking) => {
            return acc + booking.arrived_count;
          },
          0
        );
        return count_format(total_arrived_count);
      },
    },
    {
      title: t("table:yet_to_enroll"),
      dataIndex: "",
      key: "yet_to_enroll",
      render: (record) => {
        let yet_to_enroll = 0;
        let total_enrolled = record?.enrolment_list?.reduce((acc, booking) => {
          return acc + booking.arrived_count;
        }, 0);
        yet_to_enroll = record?.count - total_enrolled;
        if (yet_to_enroll < 0) {
          yet_to_enroll = 0;
          return count_format(yet_to_enroll);
        } else if (record?.cancelled_count > 0) {
          return 0;
        } else {
          return count_format(yet_to_enroll);
        }
      },
    },
    {
      title: t("table:excess"),
      dataIndex: "excess",
      key: "excess",
      render: (record) => {
        const total_enrolled = record?.enrolment_list?.reduce(
          (acc, booking) => {
            return acc + booking.arrived_count;
          },
          0
        );
        let excess = 0;
        excess = record?.count - total_enrolled;
        if (excess < 0) {
          return count_format(excess * -1);
        }
        return 0;
      },
    },
    {
      title: t("table:leader_name"),
      dataIndex: ["booking_user", "name"],
      key: "leader_name",
    },
    {
      title: t("table:leader_no"),
      dataIndex: ["booking_user", "mobile"],
      key: "leader_no",
    },
    {
      title: t("table:booked_by"),
      dataIndex: ["booked_by", "name"],
      key: "booked_by",
    },
    {
      title: t("table:payment_mode"),
      dataIndex: ["payment", "payment_mode"],
      key: "payment_mode",
      render: (record) => startCase(record) || "-",
    },
    {
      title: t("table:payment_status"),
      dataIndex: "",
      key: "payment_status",
      render: (record) => {
        console.log("payment_statuspayment_statuspayment_status", record);

        return record?.enrolment_list && record?.enrolment_list?.length > 0
          ? formatStatus(record?.enrolment_list?.[0]?.status)
          : "-";
      },
    },
    {
      title: t("table:booking_type"),
      dataIndex: "",
      key: "user_type",
      render: (record) => {
        return record?.booking_type ? startCase(record?.booking_type) : "-";
      },
    },
    {
      title: t("prepaid_count"),
      dataIndex: "",
      key: "prepaid",
      render: (record) => {
        return record?.is_prepaid === true ? "Paid" : "Paid at Temple";
      },
    },
    {
      title: t("table:status"),
      dataIndex: "booking_status",
      key: "status",
      render: formatStatus,
      fixed: "right",
    },
    {
      title: t("table:action"),
      dataIndex: "",
      key: "action",
      fixed: "right",
      render: (record) => {
        return (
          <BookingAction
            booking_details={record}
            cancelled_count={record?.cancelled_count}
          />
        );
      },
    },
  ];
  const handle_booking_receipt = (booking_details) => {
    const url = `/print-receipt/${booking_details?.booking_reference}/${booking_details?.booking_user?.mobile}`;
    const new_tab = window.open(url, "_blank", "width=850, height=900");
    if (new_tab) {
      new_tab.focus();
    }
  };
  const handle_close = () => {
    set_booking_cancel_modal(false);
  };
  const handle_mandram_close = (booking_details) => {
    set_cancel_booking_details(booking_details);
    set_booking_cancel_modal(true);
  };

  const BookingAction = ({ booking_details, cancelled_count }) => {
    return (
      <div className="booking-action">
        {cancelled_count > 0 ? null : (
          <>
            <FaPrint
              size={20}
              onClick={() => handle_booking_receipt(booking_details)}
              color="#3b90f5"
              className="icon"
            />

            {/* {role != "District User" && ( */}
            <TiCancel
              size={25}
              color="#f90000"
              onClick={() => handle_mandram_close(booking_details)}
              className="icon"
            />
            {/* )} */}
          </>
        )}
      </div>
    );
  };
  const handle_change = (page_number, page_limit) => {
    console.log("handle_change", page_number, page_limit);
  };
  return (
    <div>
      <Table
        columns={columns}
        dataSource={get_booking_list}
        loading={get_booking_list_loading}
        className="admin-table"
        rowClassName={(record, index) =>
          index % 2 === 0 ? "row-even" : "row-odd"
        }
        scroll={{
          x: 1900,
        }}
        onChange={handle_change}
      />
      <BookingHistoryCancelBookingModal
        handle_close={handle_close}
        item={cancel_booking_details}
        open={booking_cancel_modal}
      />
    </div>
  );
};

export default MandramWiseBookingList;
