import { Form, Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../../services/redux";

const DateWiseReportDistrict = ({
  set_district_id,
  select_district_option,
  set_select_district_option,
}) => {
  const { t } = useTranslation();
  const { items: date_wise_report_district_list } = useDynamicSelector(
    "date_wise_report_district_list"
  );
  const handle_change = (value) => {
    set_district_id(value);
  };

  const custom_filter_option = (input, option) => {
    const input_value = input.toLowerCase();
    const option_label = option.props.children.toLowerCase();
    return option_label.indexOf(input_value) === 0;
  };

  return (
    <Form.Item label={t("district")} name="district">
      <Select
        // mode="multiple"
        style={
          {
            // width: "100%",
          }
        }
        placeholder={t("district")}
        onChange={handle_change}
        allowClear
        showSearch
        filterOption={custom_filter_option}
      >
        {date_wise_report_district_list?.map((district) => (
          <Select.Option key={district.id} value={district.id}>
            {district.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default DateWiseReportDistrict;
