import { Button, Card, Col, Form, Modal, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import NoOfDevotees from "./receipt_details_inputs/no_of_devotees";
import PaymentModeInput from "./receipt_details_inputs/payment_mode_input";
import SelectBank from "./receipt_details_inputs/select_bank";
import ReceiptDateInput from "./receipt_details_inputs/receipt_date_input";
import ReferenceNumber from "./receipt_details_inputs/reference_number";
import {
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  query_get_asynchronous_request,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { addMinutes, format } from "date-fns";
import ReceiptDetailsCard from "./receipt_details_card";
import {
  create_enroll_booking_query,
  enrollment_check_order_status_query,
} from "../../../../services/redux/slices/graphql/graphql_enroll_booking";
import { toast } from "react-toastify";
import DdAmountInput from "./receipt_details_inputs/dd_amount_input";
import PaymentSuccessCard from "./receipt_details_inputs/payment_success_card";
import CashReceived from "./receipt_details_inputs/cash_received";
import CashBalance from "./receipt_details_inputs/cash_balance";
import PaymentReference from "./receipt_details_inputs/payment_reference";
import { retrieveItem } from "../../../../helpers/functions";
import CreateEnrolment from "../create_enrolment/create_enrolment";
import CaptureIdProof from "../search_enroll_details/capture_id_proof";

let modalInstance;

const ReceiptDetails = (props) => {
  const {
    set_enroll_form_values,
    get_enroll_booking_list,
    enroll_form_values,
    set_receipt_number,
    spot_enrol_button_ref,
    open_token_printer_modal,
    set_confirmation_modal_open,
    get_closure_details,
    open_confirmation,
    set_open_confirmation,
    receipt_form,
  } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const role = retrieveItem("role");
  const user_name=retrieveItem("system_user");
  console.log("user_name",user_name)

  const [payment_mode, set_payment_mode] = useState("");
  const [selected_date, set_selected_date] = useState("");
  const [selected_bank, set_selected_bank] = useState("");
  const [dd_amount, set_dd_amount] = useState("");
  const [no_of_devotees, set_no_of_devotees] = useState(0);
  const [total_amount, set_total_amount] = useState(0);
  const [dd_reference_number, set_dd_reference_number] = useState("");
  const [arrived_count, set_arrived_count] = useState(0);
  const [not_arrived_count, set_not_arrived_count] = useState(0);
  const [sum_not_arrived, set_sum_not_arrived] = useState(0);
  const [excess_devotee_count, set_excess_devotee_count] = useState(0);
  const [allowed_devotee, set_allowed_devotee] = useState(1);
  const [booking_state, set_booking_state] = useState();
  const [visible, set_visible] = useState(true);
  const [cash_amount, set_cash_amount] = useState(0);
  const [balance_amount, set_balance_amount] = useState(0);
  const [total_cash, set_total_cash] = useState(0);
  const [excess_count_percentage, set_excess_count_percentage] = useState(0);
  const [open_enrol_confirmation, set_open_enrol_confirmation] =
    useState(false);

  const [front_url, set_front_url] = useState("");
  const [back_url, set_back_url] = useState("");
  const {
    id,
    price,
    booking_reference,
    booking_status,
    mandram,
    count,
    booking_user,
    date_slot,
    time_slot,
    confirmed_count,
    waiting_count,
    is_prepaid,
    enrolment_list,
    is_vip,
  } = useDynamicSelector("booking_details");

  const abishekam_date = date_slot?.date_string;
  const total_booked_count = confirmed_count + waiting_count;
  const is_id_proof_required =
    is_vip  || no_of_devotees >= 58;

  useEffect(() => {
    if (no_of_devotees) {
      const total_cash_amount = no_of_devotees * price;
      set_total_cash(total_cash_amount);
    }
  }, [no_of_devotees, price]);

  useEffect(() => {
    if (payment_mode === "cash") {
      const bal_amount = cash_amount - total_amount;
      if (bal_amount < 0) {
        form.setFieldsValue({ cash_balance: 0 });
      } else {
        form.setFieldsValue({ cash_balance: bal_amount });
      }
    }
  }, [payment_mode, cash_amount, total_amount]);
  const {
    id: enrollment_id,
    status: enrollment_booking_status,
    error: enrollment_booking_error,
    loading: enrollment_booking_loading,
  } = useDynamicSelector("enroll_booking");

  const { payment_status, error: enrollment_payment_check_error } =
    useDynamicSelector("enrollment_payment_check");

  const start_time = time_slot?.start_time_in_minutes;
  const end_time = time_slot?.end_time_in_minutes;
  if (start_time && end_time) {
    const starting_time = addMinutes(new Date(0), start_time);
    const ending_time = addMinutes(new Date(0), end_time);
    const formatted_start_time = format(starting_time, "hh.mm");
    const formatted_endTime = format(ending_time, "hh.mm");
    var formatted_time_range = `${formatted_start_time} - ${formatted_endTime}`;
  }

  useEffect(() => {}), [];

  const get_booking_status = (status) => {
    switch (status) {
      case "confirmed":
        return "Confirmed";
      case "waiting":
        return "Waiting";
      case "partially_confirmed":
        return "Partially Confirmed";
      case "cancelled":
        return "Cancelled";
      default:
        return status;
    }
  };

  const handle_submit = (values) => {
    dispatch(dynamicSet("enrol_detail", values));
    set_enroll_form_values(values);
    set_open_enrol_confirmation(true);
  };

  const clear_receipt_values = () => {
    form.resetFields();
    set_enroll_form_values("");
    set_no_of_devotees("");
    set_total_amount(0);
  };

  const handle_date_change = (date, dateString) => {
    const current_date = dateString;
    console.log("current_date", current_date);

    set_selected_date(current_date);
  };

  const enrollment_payment_status = (enrollment_id) => {
    let key = [{ key: "enrollment_payment_check", loading: true }];
    let query = enrollment_check_order_status_query;
    let variables = {
      id: enrollment_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const calculate_arrived_count = (enrolmentList) => {
    return enrolmentList.reduce((sum, item) => sum + item?.arrived_count, 0);
  };

  useEffect(() => {
    if (role === "Bank Branch Enrolment Officer") {
      const extra_percentage = 20 / 100;
      set_excess_count_percentage(extra_percentage);
    } else {
      const extra_percentage =
        mandram?.district?.extra_allowed_percentage / 100;
      set_excess_count_percentage(extra_percentage);
    }
  }, [role, is_vip]);

  useEffect(() => {
    if (excess_count_percentage >= 0) {
      const excess_count = total_booked_count * excess_count_percentage;
      console.log(
        "excess_count",
        Math.round(excess_count),
        excess_count,
        excess_count_percentage,
        total_booked_count,
        excess_count_percentage
      );
      set_excess_devotee_count(Math.round(excess_count));
    }
  }, [excess_count_percentage, total_booked_count, arrived_count]);

  useEffect(() => {
    console.log(
      "excess_devotee_count",
      excess_devotee_count,
      total_booked_count
    );
    if (excess_devotee_count >= 0) {
      const extra_devotee = excess_devotee_count + total_booked_count;
      set_allowed_devotee(extra_devotee);
    }
  }, [excess_devotee_count, total_booked_count]);

  useEffect(() => {
    if (enrolment_list) {
      const sum_arrived = calculate_arrived_count(enrolment_list);
      set_arrived_count(sum_arrived);
      const sum_not_arrived = total_booked_count - sum_arrived;
      set_sum_not_arrived(sum_not_arrived);
    }
  }, [enrolment_list, total_booked_count]);

  useEffect(() => {
    if (sum_not_arrived > 0) {
      set_not_arrived_count(sum_not_arrived);
    } else if (sum_not_arrived < 0) {
      set_not_arrived_count(0);
    }
  }, [sum_not_arrived]);

  useEffect(() => {
    if (booking_status) {
      set_booking_state(get_booking_status(booking_status));
    }
  }, [booking_status]);

  useEffect(() => {
    if (payment_mode === "dd") {
      form.setFieldsValue({ dd_amount: total_amount });
    }
  }, [payment_mode, total_amount]);

  useEffect(() => {
    if (payment_status === "initiated") {
      setTimeout(() => {
        enrollment_payment_status(enrollment_id);
      }, 4000);
    } else if (payment_status === "completed") {
      if (modalInstance) {
        modalInstance.destroyAll();
      }
      set_open_confirmation(false);
      get_enroll_booking_list();
      get_closure_details();
      set_receipt_number("");
      open_token_printer_modal();
      set_balance_amount(0);
      form.setFieldsValue({ cash_balance: 0 });
    } else if (enrollment_payment_check_error) {
      if (modalInstance) {
        modalInstance.destroyAll();
      }
      toast.error(enrollment_payment_check_error?.message);
      dispatch(dynamicClear("enrollment_payment_check"));
    }
  }, [payment_status, enrollment_id]);

  useEffect(() => {
    if (enrollment_booking_status === "enrolled") {
      open_token_printer_modal();
      get_enroll_booking_list();
      set_receipt_number("");
      set_payment_mode("");
      set_balance_amount(0);
      form.setFieldsValue({ cash_balance: 0 });
      spot_enrol_button_ref.current.focus();
      dispatch(dynamicClear("booking_details"));
    } else if (enrollment_booking_status === "booking created & paid") {
      open_token_printer_modal();
      get_enroll_booking_list();
      set_receipt_number("");
      set_payment_mode("");
      set_balance_amount(0);
      spot_enrol_button_ref.current.focus();
      dispatch(dynamicClear("booking_details"));
      dispatch(dynamicClear("enroll_booking"));
    } else if (enrollment_booking_status === "order created") {
      enrollment_payment_status(enrollment_id);
      modalInstance = Modal.info({
        title: "Payment Process",
        content: (
          <div>
            <p>Your Payment is Processing......</p>
          </div>
        ),
        closable: false,
        okButtonProps: {
          style: {
            display: "none",
          },
        },
      });
    } else if (enrollment_booking_error) {
      if (modalInstance) {
        modalInstance.destroyAll();
      }
      toast.error(enrollment_booking_error?.message);
      set_receipt_number("");
      dispatch(dynamicClear("enroll_booking"));
      dispatch(dynamicClear("booking_details"));
    }
  }, [
    enrollment_booking_status,
    enrollment_booking_error,
    spot_enrol_button_ref,
    enrollment_id,
  ]);

  return (
    <Card
      title={booking_reference}
      headStyle={{
        backgroundColor: "#275670",
        color: "white",
        fontSize: "20px",
      }}
      style={{
        boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.4)",
      }}
    >
      <Form form={form} onFinish={handle_submit}>
        <Row gutter={16}>
          <Col span={16}>
            <Card
              title={"Booking Details"}
              headStyle={{
                backgroundColor: "#275670",
                width: "100%",
                color: "white",
                textAlign: "center",
                fontSize: "17px",
                height: "10px",
              }}
              style={{
                boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.4)",
                height: "auto",
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "26%" }}>
                  <ReceiptDetailsCard
                    title="Abishekam Date"
                    sub_title={abishekam_date}
                  />
                </div>
                <div style={{ width: "26%" }}>
                  <ReceiptDetailsCard
                    title="Time"
                    sub_title={formatted_time_range}
                  />
                </div>
                <div style={{ width: "26%" }}>
                  <ReceiptDetailsCard
                    title="Devotees Count"
                    sub_title={total_booked_count}
                  />
                </div>
                <div style={{ width: "26%", marginTop: "10px" }}>
                  <ReceiptDetailsCard
                    title="District"
                    sub_title={mandram?.district?.name}
                  />
                </div>
                <div style={{ width: "26%", marginTop: "10px" }}>
                  <ReceiptDetailsCard
                    title="Mandram (VSG)"
                    sub_title={mandram?.code}
                    sub_value={mandram?.name}
                  />
                </div>
                <div style={{ width: "26%", marginTop: "10px" }}>
                  <ReceiptDetailsCard
                    title="Status"
                    sub_title={booking_state}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title={"Count"}
              headStyle={{
                backgroundColor: "#275670",
                color: "white",
                textAlign: "center",
                width: "100%",
                fontSize: "17px",
                height: "10px",
              }}
              style={{
                boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.4)",
                height: "auto",
                marginBottom: 20,
              }}
            >
              <Col>
                <div>
                  <div style={{ display: "inline-block", width: "150px" }}>
                    <span style={{ color: "green", fontWeight: "bold" }}>
                      Enrolled
                    </span>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    {arrived_count}
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <div style={{ display: "inline-block", width: "150px" }}>
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      Yet to Enroll
                    </span>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    {not_arrived_count}
                  </div>
                </div>
                <div style={{ marginTop: "13px" }}>
                  <div style={{ display: "inline-block", width: "150px" }}>
                    <span style={{ color: "#5c8a7d", fontWeight: "bold" }}>
                      Excess
                    </span>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      color: "#5c8a7d",
                      fontWeight: "bold",
                    }}
                  >
                    {arrived_count - total_booked_count > 0
                      ? arrived_count - total_booked_count
                      : 0}
                  </div>
                </div>
              </Col>
            </Card>
          </Col>
        </Row>

        <Card
          title={"Leader Details"}
          headStyle={{
            backgroundColor: "#275670",
            color: "white",
            textAlign: "center",
            fontSize: "17px",
            height: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          style={{
            boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.4)",
            width: "100%",
            height: "auto",
            marginBottom: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "26%" }}>
              <ReceiptDetailsCard
                title={"Name"}
                sub_title={booking_user?.name}
              />
            </div>
            <div style={{ width: "26%" }}>
              <ReceiptDetailsCard
                title={"Mobile Number"}
                sub_title={booking_user?.mobile}
              />
            </div>
            <div style={{ width: "26%" }}>
              <ReceiptDetailsCard
                title={"Address"}
                sub_title={booking_user?.address}
              />
            </div>
            <div style={{ width: "26%" }}>
              <ReceiptDetailsCard
                title={"ID Type"}
                sub_title={booking_user?.id_type}
              />
            </div>
            <div style={{ marginRight: "51%", width: "12%" }}>
              <ReceiptDetailsCard
                title={"ID Number"}
                sub_title={booking_user?.id_number}
              />
            </div>
          </div>
        </Card>
        <Card
          title={"Enrollment Details"}
          headStyle={{
            backgroundColor: "#275670",
            color: "white",
            textAlign: "center",
            fontSize: "17px",
            height: "10px",
          }}
          style={{
            boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.4)",
            width: "100%",
            height: "auto",
            marginBottom: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "26%" }}>
              <NoOfDevotees
                set_no_of_devotees={set_no_of_devotees}
                no_of_devotees={no_of_devotees}
                arrived_count={arrived_count}
                confirmed_count={total_booked_count}
                price={price}
                set_total_amount={set_total_amount}
                excess_devotee_count={excess_devotee_count}
                allowed_devotee={allowed_devotee}
                set_visible={set_visible}
                form={form}
              />
            </div>

            {is_prepaid === false && (
              <>
                <div style={{ width: "26%" }}>
                  <ReceiptDetailsCard
                    title={"Rate"}
                    sub_title={price}
                    symbol={"₹"}
                  />
                </div>
                <div style={{ width: "26%" }}>
                  <ReceiptDetailsCard
                    title={"Total Amount"}
                    amount={total_amount}
                    currency={"₹"}
                  />
                </div>
              </>
            )}
          </div>
          {(is_id_proof_required && user_name!=="addn")&&(
            <CaptureIdProof
              set_front_url={set_front_url}
              front_url={front_url}
              set_back_url={set_back_url}
              back_url={back_url}
            />
          )}
        </Card>
        {is_prepaid === false && (
          <Card
            title={"Make Payment"}
            headStyle={{
              backgroundColor: "#275670",
              color: "white",
              textAlign: "center",
              fontSize: "17px",
              height: "10px",
            }}
            style={{
              boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.4)",
              width: "100%",
              height: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "50%" }}>
                <PaymentModeInput
                  payment_mode={payment_mode}
                  set_payment_mode={set_payment_mode}
                  form={form}
                />
              </div>
            </div>
            {payment_mode === "dd" ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "20%" }}>
                  <DdAmountInput
                    set_dd_amount={set_dd_amount}
                    total_amount={total_amount}
                  />
                </div>
                <div style={{ width: "20%" }}>
                  <SelectBank
                    set_selected_bank={set_selected_bank}
                    payment_mode={payment_mode}
                  />
                </div>
                <div style={{ width: "20%" }}>
                  <ReceiptDateInput
                    set_selected_date={set_selected_date}
                    selected_date={selected_date}
                    handle_date_change={handle_date_change}
                    payment_mode={payment_mode}
                  />
                </div>
                <div style={{ width: "20%" }}>
                  <ReferenceNumber
                    payment_mode={payment_mode}
                    set_dd_reference_number={set_dd_reference_number}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {payment_mode === "cash" ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CashReceived set_cash_amount={set_cash_amount} />
                <CashBalance />
              </div>
            ) : (
              ""
            )}
            {payment_mode === "upi" || payment_mode === "card" ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <PaymentReference payment_mode={payment_mode} />
              </div>
            ) : (
              ""
            )}
          </Card>
        )}
        {is_prepaid === true && <PaymentSuccessCard />}
        <div style={{ marginLeft: "80%", marginTop: "20px" }}>
          <Form.Item>
            <Button
              style={{
                backgroundColor: "#1f8cb9",
                color: "white",
                marginRight: "9%",
                border: "transparent",
              }}
              htmlType="submit"
              disabled={visible ? false : true}
            >
              Submit
            </Button>
            <Button
              style={{
                backgroundColor: "#c79c13",
                color: "white",
                border: "transparent",
              }}
              onClick={clear_receipt_values}
            >
              Clear
            </Button>
          </Form.Item>
        </div>
      </Form>

      <CreateEnrolment
        get_enroll_booking_list={get_enroll_booking_list}
        open_enrol_confirmation={open_enrol_confirmation}
        form={form}
        set_open_enrol_confirmation={set_open_enrol_confirmation}
        spot_enrol_button_ref={spot_enrol_button_ref}
        payment_mode={payment_mode}
        set_front_url={set_front_url}
        front_url={front_url}
        set_back_url={set_back_url}
        back_url={back_url}
        receipt_form={receipt_form}
      />
    </Card>
  );
};

export default ReceiptDetails;
