import { gql } from "@apollo/client";

export const get_date_slots = gql`
  query get_season_date_slot_list($sort: sort_input) {
    counter_user_wise_report_date_slots: get_season_date_slot_list(
      sort: $sort
    ) {
      items {
        id
        date_string
        date
      }
    }
  }
`;

export const get_counter_payment_summary = gql`
  query get_counter_user_wise_summary(
    $filter: counter_user_wise_summary_filter_input
  ) {
    get_counter_user_wise_summary(filter: $filter) {
      items {
        counter_name
        sum
        total_devotees
        cash_amount
        dd_amount
        card_amount
        upi_amount
        online_amount
      }
    }
  }
`;

export const query_get_one_counter_name = gql`
  query get_counter($id: String!) {
    get_one_counter_name: get_counter(id: $id) {
      name
    }
  }
`;

export const query_get_counter_list = gql`
  query get_counter_list($sort: sort_input) {
    get_all_counter_list: get_counter_list(sort: $sort) {
      items {
        id
        name
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;
