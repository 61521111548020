import { gql } from "@apollo/client"

export const get_mandram_list_query=gql `
query get_mandram_list(
  $filter: get_mandram_list_filter_input
  $sort: sort_input
  $pagination: pagination_input
) {
    get_mandram_list(filter: $filter, sort: $sort, pagination: $pagination) {
    items {
      id
      name
      code
      district_id
      incharge_name
      incharge_mobile
      is_active
      activation_status
      sub_district{
      id
      name
      }
      district {
        order_number
        tamil_name
        code
        name
        id
      }
      admin {
        name
        mobile
        email
        system_user {
          id
          username
          name
          email
          mobile
        }
      }
    }
    pagination{
      total_count
      page_number
      page_limit
      
    }
    error{
      status_code
      message
    }
  }
}
`;

  export const create_mandram_query=gql`
  mutation create_mandram($data: create_mandram_input!) {
    create_mandram(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
  `;
export const query_get_mandram=gql`
query get_mandram($id:String!) {
  get_mandram (id:$id){
    id
    name
    code
    district_id
    incharge_name
    incharge_mobile
    sub_district_id
    is_active
    district{
      order_number
      tamil_name
      name
    }
    admin{
      name
      mobile
      email
      system_user{
        id
        username
        name
      }
    }
  }
}`;

export const delete_mandram_query=gql`
mutation delete_mandram($id:String!){
  delete_mandram(id:$id){
    id
    status
    error{
      message
    }
  }
}`;

export const update_mandram_query=gql`
mutation update_mandram($id:String!,$data:update_mandram_input!){
  update_mandram(id:$id,data:$data){
    id
    status
    error{
      message
    }
  }
}
`;

export const query_get_mandram_list=gql`
query get_mandram_list(
  $filter: get_mandram_list_filter_input
  $sort: sort_input
) {
  get_mandram_details:get_mandram_list(filter: $filter, sort: $sort) {
    items {
      id
      name
      code
      incharge_name
      incharge_mobile
      district_id
    }
  }
}
`
export const query_get_all_mandrams=gql`
query get_mandram_list(
  $filter: get_mandram_list_filter_input
  $sort: sort_input
) {
  get_all_mandrams:get_mandram_list(filter: $filter, sort: $sort) {
    items {
      id
      name
      code
      incharge_name
      incharge_mobile
      district_id
    }
  }
}`

export const mandram_list_query=gql`
query get_mandram_list($filter:get_mandram_list_filter_input){
  mandram_search_input:get_mandram_list(filter:$filter){
    items{
      id
      name
      code
    }
    pagination{
      total_count
      page_number
      page_limit
    }
    error{
      status_code
       message
    }
  }
}`;

export const bulk_mandram_update=gql`
mutation  update_many_mandram_status($data:update_many_mandram_status_input){
  update_many_mandram_status(data:$data) {
    id
    status
    error {
      status_code
      message
    }
  }
}
`

export const mutation_update_mandram_status=gql`
mutation update_mandram_status($id: String!, $activation_status: String!,$remarks:String!) {
  update_mandram_status(id: $id, activation_status: $activation_status,remarks:$remarks) {
    id
    status
    error {
      message
    }
  }
}
`

export const mutation_upload_excel=gql`
mutation excel_mandram_renewal($excel_url:String!){
  excel_mandram_renewal(excel_url:$excel_url){
    id
    status
    error{
      message
    }
  }
}`