import { Row, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { dynamicClear, useDynamicSelector } from "../../../../services/redux";
import {
  export_to_xlsx,
  getAmountFormatWithSymbol,
} from "../../../../helpers/functions";
import CustomButton from "../../common/custom_button";
import UserCounterWiseReportModal from "../modal/user_counter_wise_report_modal";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import PrintHeader from "../../common/print_header";
import moment from "moment";
import { StyleSheet } from "@react-pdf/renderer";
import { IS_TABLE_SCROLLABLE } from "../../../../helpers/constants";

const UserCounterWiseReportList = ({ selected_date }) => {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const [visible, set_visible] = useState(false);

  useEffect(() => {
    dispatch(dynamicClear("get_user_counter_wise_summary"));
  }, []);

  const { items: user_counter_wise_items, loading: counter_user_wise_loading } =
    useDynamicSelector("get_user_counter_wise_summary");

  const styles = StyleSheet.create({
    tableCell: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  });

  const total_received_cash = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.cash_amount || 0),
        0
      )
    : 0;
  const total_dd_enrolments = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.dd_enrolments || 0),
        0
      )
    : 0;
  const total_card_enrolments = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.card_enrolments || 0),
        0
      )
    : 0;

  const total_card_amount = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.card_amount || 0),
        0
      )
    : 0;
  const total_dd_amount = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.dd_amount || 0),
        0
      )
    : 0;
  const total_upi_enrolments = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.upi_enrolments || 0),
        0
      )
    : 0;

  const total_upi_amount = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.upi_amount || 0),
        0
      )
    : 0;
    const total_online_enrolments = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.online_enrolments || 0),
        0
      )
    : 0;

  const total_online_amount = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.online_amount || 0),
        0
      )
    : 0;

  const totalSum = user_counter_wise_items?.reduce(
    (acc, item) => acc + (item?.sum || 0),
    0
  );

  const total_devotees = user_counter_wise_items?.reduce(
    (acc, item) => acc + (item?.total_devotees || 0),
    0
  );

  const total_total_enrolments = user_counter_wise_items?.reduce(
    (acc, item) => acc + (item?.total_enrolments || 0),
    0
  );

  const grand_total_enrolment = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.total_enrolments || 0),
        0
      )
    : 0;

  const grand_total_devotees = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.total_devotees || 0),
        0
      )
    : 0;

  const grand_total_cash_amount = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.cash_amount || 0),
        0
      )
    : 0;

  const grand_total_cash_enrolments = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.cash_enrolments || 0),
        0
      )
    : 0;

  const grand_total_card_enrolments = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.card_enrolments || 0),
        0
      )
    : 0;

  const grand_total_card_amount = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.card_amount || 0),
        0
      )
    : 0;

  const grand_total_upi_amount = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.upi_amount || 0),
        0
      )
    : 0;
  const grand_total_upi_enrolments = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.upi_enrolments || 0),
        0
      )
    : 0;
    const grand_total_online_amount = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.online_amount || 0),
        0
      )
    : 0;
  const grand_total_online_enrolments = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.online_enrolments || 0),
        0
      )
    : 0;
  const grand_total = user_counter_wise_items
    ? user_counter_wise_items.reduce(
        (sum, record) => sum + (record.sum || 0),
        0
      )
    : 0;

  const totals = {
    counter_name: <b>Total</b>,
    total_devotees: <b>{`${total_devotees}`}</b>,
    sum: <b>{`₹${totalSum}`}</b>,
    cash_amount: <b>{`₹${total_received_cash}`}</b>,
    dd_enrolments: `${total_dd_enrolments} `,
    dd_amount: total_dd_amount,
    card_enrolments: <b>{`${total_card_enrolments}`}</b>,
    card_amount: <b>{`₹${total_card_amount}`}</b>,
    upi_enrolments: <b>{`${total_upi_enrolments}`}</b>,
    upi_amount: <b>{`₹${total_upi_amount}`}</b>,
    online_enrolments: <b>{`${total_online_enrolments}`}</b>,
    online_amount: <b>{`₹${total_online_amount}`}</b>,
    total_enrolments: <b>{`${total_total_enrolments}`}</b>,
  };

  const columns = [
    {
      title: "Counter",
      dataIndex: "counter_name",
      key: "counter_name",
      sticky: "top",
      fixed: "left",
      width: "120px",
      align: "left",
    },
    {
      title: "Username",
      dataIndex: "user_name",
      key: "user_name",
      align: "left",
    },
    {
      title: "All Enrolments",
      dataIndex: "total_enrolments",
      key: "total_enrolments",
      align: "right",
    },
    {
      title: "Devotees",
      key: "total_devotees",
      align: "center",
      render: (record) => record?.total_devotees,
      align: "right",
    },
    {
      title: "Cash Amount",
      key: "cash_amount",
      align: "right",
      render: (record) => `₹${record?.cash_amount}`,
    },

    {
      title: "Card Enrolments",
      key: "card_enrolments",
      align: "right",
      render: (record) => `${record?.card_enrolments}`,
    },
    {
      title: "Card Amount",
      key: "card_amount",
      align: "right",
      render: (record) => `₹${record?.card_amount}`,
    },
    {
      title: "Cash Enrolments",
      key: "cash_enrolments",
      align: "right",
      render: (record) => record?.cash_enrolments,
    },
    {
      title: "UPI Enrolments",
      key: "upi_enrolments",
      align: "right",
      render: (record) => record?.upi_enrolments,
    },
    {
      title: "UPI Amount",
      key: "upi_amount",
      align: "right",
      render: (record) => `₹${record?.upi_amount}`,
    },
    {
      title: "ONLINE Enrolments",
      key: "online_enrolments",
      align: "right",
      render: (record) => record?.online_enrolments,
    },
    {
      title: "ONLINE Amount",
      key: "online_amount",
      align: "right",
      render: (record) => `₹${record?.online_amount}`,
    },
    {
      title: "Total Amount",
      key: "sum",
      align: "right",
      fixed: "right",
      render: (record) => (record?.sum ? `₹${record?.sum}` : "-"),
    },
  ];

  const arrange_xlsx_report_data = (data_items) => {
    const rearranged_data = [];
    let grand_total = {
      ["S No"]: "Grand Total",
      ["Counter"]: "",
      ["All Enrollment"]: 0,
      ["Devotees"]: 0,
      ["Cash Amount"]: 0,
      ["Card Enrollments"]: 0,
      ["Card Amount"]: 0,
      ["UPI Enrollments"]: 0,
      ["UPI Amount"]: 0,
      ["ONLINE Enrollments"]: 0,
      ["ONLINE Amount"]: 0,
      ["Total Amount"]: 0,
    };

    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S No"]: i + 1,
        ["Counter"]: data_items[i].counter_name,
        ["All Enrollment"]: data_items[i]?.total_enrolments || 0,
        ["Devotees"]: data_items[i]?.total_devotees || 0,
        ["Cash Amount"]: data_items[i]?.cash_amount || 0,
        ["Card Enrollments"]: data_items[i]?.card_enrolments || 0,
        ["Card Amount"]: data_items[i]?.card_amount || 0,
        ["UPI Enrollments"]: data_items[i]?.upi_enrolments || 0,
        ["UPI Amount"]: data_items[i]?.upi_amount || 0,
        ["ONLINE Enrollments"]: data_items[i]?.online_enrolments || 0,
        ["ONLINE Amount"]: data_items[i]?.online_amount || 0,
        ["Total Amount"]: data_items[i]?.sum || 0,
      };

      for (let key in grand_total) {
        grand_total[key] += parseFloat(row_data[key]) || 0;
      }

      rearranged_data.push(row_data);
    }

    rearranged_data.push(grand_total);

    return rearranged_data;
  };

  const on_xlsx_download = () => {
    let arranged_data = arrange_xlsx_report_data(user_counter_wise_items);
    export_to_xlsx(arranged_data, "User counter wise report");
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const on_print = () => {
    handlePrint();
  };

  return (
    <>
      {user_counter_wise_items?.length > 0 && (
        <>
          <Row
            style={{
              width: "96%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <CustomButton
              disabled={user_counter_wise_items?.length > 0 ? false : true}
              id="daily_transaction_print_button"
              button_name={"Print"}
              bg_color={"#1e2c55"}
              rounded={true}
              onclick_button={on_print}
            />
            <CustomButton
              disabled={user_counter_wise_items?.length > 0 ? false : true}
              button_name={"Download"}
              bg_color={"#185c37"}
              rounded={true}
              onclick_button={on_xlsx_download}
            />
          </Row>
          <Table
            className="screen-table"
            data-name-table="district-wise-report"
            style={{ margin: "20px", marginTop: "40px" }}
            columns={columns}
            dataSource={
              user_counter_wise_items ? [...user_counter_wise_items] : []
            }
            loading={counter_user_wise_loading}
            pagination={false}
            scroll={{
              x: 1920,
              y: IS_TABLE_SCROLLABLE ? null : 300,
            }}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <text style={{ fontWeight: "bold" }}>TOTAL</text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <text style={styles.tableCell}>
                      <b>{total_total_enrolments}</b>
                    </text>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={2}>
                    <text style={styles.tableCell}>
                      <b>{grand_total_devotees}</b>
                    </text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <text style={styles.tableCell}>
                      <b>
                        {getAmountFormatWithSymbol(grand_total_cash_amount)}
                      </b>
                    </text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    <text style={styles.tableCell}>
                      <b>{grand_total_card_enrolments}</b>
                    </text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    <text style={styles.tableCell}>
                      <b>
                        {" "}
                        {getAmountFormatWithSymbol(grand_total_card_amount)}
                      </b>
                    </text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <text style={styles.tableCell}>
                      <b> {grand_total_cash_enrolments}</b>
                    </text>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={7}>
                    <text style={styles.tableCell}>
                      <b>{grand_total_upi_enrolments}</b>
                    </text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    <text style={styles.tableCell}>
                      <b>{getAmountFormatWithSymbol(grand_total_upi_amount)}</b>
                    </text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <text style={styles.tableCell}>
                      <b>{grand_total_online_enrolments}</b>
                    </text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    <text style={styles.tableCell}>
                      <b>{getAmountFormatWithSymbol(grand_total_online_amount)}</b>
                    </text>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={9}>
                    <text style={styles.tableCell}>
                      <b>{getAmountFormatWithSymbol(grand_total)}</b>
                    </text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          ></Table>
        </>
      )}
      <div
        style={{
          // display: "flex",
          margin: "auto",
          width: "100%",
        }}
        ref={componentRef}
      >
        <div className="print-header" style={{ padding: "2px" }}>
          <PrintHeader title={"COUNTER USER WISE REPORT"} />
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            USER COUNTER WISE REPORT
          </h4>
          <h5
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {selected_date?.option?.least_date ===
            selected_date?.option?.last_date
              ? selected_date?.option?.least_date
              : `From ${selected_date?.option?.least_date} To ${selected_date?.option?.last_date}`}
          </h5>
        </div>

        <div style={{ width: "100%" }}>
          <table
            className="table table-bordered print-table"
            style={{ width: "100%", marginTop: "10px" }}
          >
            <thead style={{ width: "100%" }}>
              <tr>
                {columns.map((column) => (
                  <th
                    className="header-style"
                    style={{ width: "100px" }}
                    key={column.key}
                  >
                    {column.title}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody style={{ width: "100%" }}>
              {user_counter_wise_items?.map((report, index) => (
                <tr key={index}>
                  <td className="body-style">{report?.counter_name}</td>
                  <td className="body-style ">{report?.user_name}</td>

                  <td className="body-style " style={{ textAlign: "right" }}>
                    {report?.total_enrolments}
                  </td>

                  <td className="body-style" style={{ textAlign: "right" }}>
                    {report?.total_devotees}
                  </td>
                  <td className="body-style" style={{ textAlign: "right" }}>
                    {getAmountFormatWithSymbol(report?.cash_amount)}
                  </td>
                  <td className="body-style" style={{ textAlign: "right" }}>
                    {report?.card_enrolments}
                  </td>
                  <td className="body-style" style={{ textAlign: "right" }}>
                    {getAmountFormatWithSymbol(report?.card_amount)}
                  </td>
                  <td className="body-style" style={{ textAlign: "right" }}>
                    {report?.cash_enrolments}
                  </td>
                  <td className="body-style" style={{ textAlign: "right" }}>
                    {report?.upi_enrolments}
                  </td>
                  <td className="body-style" style={{ textAlign: "right" }}>
                    {getAmountFormatWithSymbol(report?.upi_amount)}
                  </td>
                  <td className="body-style" style={{ textAlign: "right" }}>
                    {report?.online_enrolments}
                  </td>
                  <td className="body-style" style={{ textAlign: "right" }}>
                    {getAmountFormatWithSymbol(report?.online_amount)}
                  </td>
                  <td className="body-style" style={{ textAlign: "right" }}>
                    {getAmountFormatWithSymbol(report?.sum)}
                  </td>
                </tr>
              ))}
              <tr>
                <td className="body-style text-left-align">
                  <b>Total</b>
                </td>
                <td className="body-style "></td>
                <td className="body-style " style={{ textAlign: "right" }}>
                  <b> {grand_total_enrolment}</b>
                </td>
                <td className="body-style " style={{ textAlign: "right" }}>
                  <b>{grand_total_devotees}</b>
                </td>
                <td className="body-style " style={{ textAlign: "right" }}>
                  <b>{getAmountFormatWithSymbol(grand_total_cash_amount)}</b>
                </td>
                <td className="body-style " style={{ textAlign: "right" }}>
                  <b>{grand_total_card_enrolments}</b>
                </td>
                <td className="body-style " style={{ textAlign: "right" }}>
                  <b>{getAmountFormatWithSymbol(grand_total_card_amount)}</b>
                </td>

                <td className="body-style " style={{ textAlign: "right" }}>
                  <b>{grand_total_cash_enrolments}</b>
                </td>

                <td className="body-style " style={{ textAlign: "right" }}>
                  <b>{grand_total_upi_enrolments}</b>
                </td>

                <td className="body-style " style={{ textAlign: "right" }}>
                  <b>{getAmountFormatWithSymbol(grand_total_upi_amount)}</b>
                </td>
                <td className="body-style " style={{ textAlign: "right" }}>
                  <b>{grand_total_online_enrolments}</b>
                </td>

                <td className="body-style " style={{ textAlign: "right" }}>
                  <b>{getAmountFormatWithSymbol(grand_total_online_amount)}</b>
                </td>
                <td className="body-style " style={{ textAlign: "right" }}>
                  <b>{getAmountFormatWithSymbol(grand_total)}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <style>
          {`
    @media screen {
      .print-table {
        display: none;
      }
      .print-header {
        display: none;
      }
    }

    @media print {
      .screen-table {
        display: none !important;
      }
      .print-header {
        display: block !important;
      }

      @media print {
        @page {
          size: A4;
          margin: 1cm;
        }
  
        body::before {
          content: counter(page);
          top: 0;
          right: 1cm;
        }

      }
    }
  `}
        </style>
      </div>

      <UserCounterWiseReportModal
        visible={visible}
        set_visible={set_visible}
        selected_date={selected_date}
      />
    </>
  );
};

export default UserCounterWiseReportList;
