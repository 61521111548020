import React, { useEffect, useState } from "react";
import MandramList from "../components/mandram/mandram_list";
import { Button, Col, Pagination, Row, Select } from "antd";
import Title from "antd/lib/typography/Title";
import SearchDistrict from "../components/mandram/mandram_search_inputs/search_district";
import AddMandramModal from "../components/mandram/add_mandram/add_mandram_modal";
import {
  dynamicRequest,
  get_mandram_list_query,
  query_get_mandram,
} from "../../services/redux/slices";
import { useDispatch } from "react-redux";
import { useDynamicSelector } from "../../services/redux/selector";
import { CustomSearchBox } from "../components/common/custom_search_box";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import { get_district_details_query } from "../../services/redux/slices/graphql/graphql_district";
import { retrieveItem } from "../../helpers/functions";
import { useTranslation } from "react-i18next";
import { countFormat } from "../../helpers/constants";
import { usePageComponentAccess } from "../../helpers/auth";
import SubDistrictFilter from "../components/sub_district/filter_sub_district/sub_district_filter";

const MandramVsg = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = retrieveItem("role");
  const { items: district_items } = useDynamicSelector("get_district_values");
  const { pagination } = useDynamicSelector("get_mandram_list");

  const [add_mandram, set_add_mandram] = useState("");
  const [header_type, set_header_type] = useState("");
  const [selected_district_id, set_selected_district_id] = useState("");
  const [sub_district_id, set_sub_district_id] = useState("");
  const [search_code,set_search_code]=useState("")

  console.log("selected_district_id",selected_district_id,sub_district_id)

  const [current, set_current] = useState(1);
  const [current_limit, set_current_limit] = useState(10);
  const [activate_key, set_activate_key] = useState([]);
  const [active_status, set_active_status] = useState();

  

  const {
    loading: bulk_update_loading,
    status: bulk_update_status,
    error: bulk_error_status,
  } = useDynamicSelector("update_many_mandram_status");

  const status_options = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];

  const handle_add_gsv = () => {
    set_header_type("Add Mandram");
    set_add_mandram(true);
  };

  const get_one_mandram = (id) => {
    get_mandram(id);
  };

  const on_search = (search_string) => {
    get_all_mandram_list(search_string);
    set_search_code(search_string)
    // set_current(1)
    // set_current_limit(10)
  };

  const get_all_mandram_list = (search_string) => {
    let key = [{ key: "get_mandram_list", loading: true }];
    let query = get_mandram_list_query;
    let filter = {
      district_id: selected_district_id,
      sub_district_id: sub_district_id,
      search_string: search_string,
    };
    if (
      role === "Super Admin" ||
      role === "MASM Admin" ||
      role === "Mandram Renewal Officer"
    ) {
      filter.do_show_all_mandram = true;
      filter.is_active = active_status;
    }
    let variables = {
      pagination: {
        page_number: current,
        page_limit: current_limit,
      },
      filter: filter,
      sort: {
        field: "internal_code",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_mandram = (mandram_id) => {
    let key = [{ key: "get_mandram", loading: true }];
    let query = query_get_mandram;
    let variables = {
      id: mandram_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_district_options = () => {
    let key = [{ key: "get_district_values", loading: true }];
    let query = get_district_details_query;
    let variables = {
      sort: {
        order: "asc",
        field: "name",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (bulk_update_status === "success") {
      toast.success(t("bulk_updated_successfully"));
      dispatch(dynamicClear("update_many_mandram_status"));
    } else if (bulk_error_status) {
      set_activate_key([]);
    }
  }, [bulk_error_status, bulk_update_status]);

  const fetch_data = (page, page_size) => {
    let keys = [{ key: "get_mandram_list", loading: true }];
    let query = get_mandram_list_query;
    let filter = {
      district_id: selected_district_id,
      sub_district_id: sub_district_id,
    };
    if (
      role === "Super Admin" ||
      role === "MASM Admin" ||
      role === "Mandram Renewal Officer"
    ) {
      filter.do_show_all_mandram = true;
      filter.is_active = active_status;
      filter.search_string=search_code
    }
    let variable = {
      pagination: {
        page_number: page,
        page_limit: page_size,
      },
      sort: {
        order: "asc",
        field: "internal_code",
      },
      filter: filter,
    };
    dispatch(dynamicRequest(keys, query, variable));
    set_current_limit(10);
    set_current(page);
  };
  const update_many_mandram = (id_list) => {
    let keys = [{ key: "update_many_mandram_status", loading: true }];
    dispatch(
      dynamicRequest(keys, bulk_mandram_update, {
        data: { id_list: id_list, status: "paid" },
      })
    );
  };
  const handle_bulk_active = () => {
    update_many_mandram(activate_key);
  };

  const on_change_status = (value) => {
    set_active_status(value);
    set_current_limit(10);
    set_current(1);
  };

  useEffect(() => {
    get_all_mandram_list();
    get_district_options();
  }, [selected_district_id, sub_district_id, active_status]);

  return (
    <div style={{ margin: "8px", width: "98%", height: "100%" }}>
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Title level={4}>{t("mandrams_vsg")}</Title>
        {/* <CustomBreadCrumbs pages={"Home/Mandram"} /> */}
        <p></p>
      </Row>
      <div style={{ marginLeft: "15px" }}>
        {role === "Super Admin" ||
        role === "MASM Admin" ||
        role === "MASM User" ? (
          <Title level={5} style={{ width: "250px" }}>
            Total Mandrams ({pagination?.total_count || 0})
          </Title>
        ) : (
          ""
        )}
      </div>

      <Col
        style={{
          height: "auto",
          margin: "10px",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "8px",
          }}
        >
          <div>
            {role === "Super Admin" ||
            role === "MASM Admin" ||
            role === "MASM User" || role === "Mandram Renewal Officer"||
            usePageComponentAccess("bulk_update_mandram_action") ? (
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex" }}>
                  <Title level={5} style={{ width: "80px" }}>
                    {t("district_search")} :
                  </Title>
                  <SearchDistrict
                    set_selected_district_id={set_selected_district_id}
                    district_items={district_items}
                  />
                  <SubDistrictFilter
                    get_district_id={selected_district_id}
                    set_sub_district_id={set_sub_district_id}
                  />
                </div>
              </div>
            ) : (
              <Title level={5} style={{ width: "170px" }}>
                Total Mandrams ({pagination?.total_count || 0})
              </Title>
            )}
          </div>
          {(role === "Super Admin" ||
            role === "MASM Admin" ||
            role === "Mandram Renewal Officer") && (
            <Select
              placeholder="Status"
              allowClear
              onChange={on_change_status}
              style={{ width: "350px", marginLeft: "10px" }}
            >
              {status_options?.map((status) => (
                <Select.Option key={status?.value} value={status?.value}>
                  {status?.label}
                </Select.Option>
              ))}
            </Select>
          )}

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ display: "flex" }}>
              <CustomSearchBox
                placeholder={"Search Mandram"}
                on_search={on_search}
              />
              {usePageComponentAccess("bulk_update_mandram_action") && (
                <Button
                  type="primary"
                  disabled={activate_key?.length === 0}
                  onClick={handle_bulk_active}
                  loading={bulk_update_loading}
                >
                  {t("bulk_activate")}
                </Button>
              )}
              {role === "Super Admin" ||
              role === "MASM Admin" ||
              role === "Mandram Renewal Officer" ? (
                <Button
                  style={{
                    backgroundColor: "#168f92",
                    color: "white",
                    border: "transparent",
                    height: "36px",
                    marginLeft: "10px",
                  }}
                  onClick={handle_add_gsv}
                >
                  {t("add_mandram")}
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <MandramList
          set_add_mandram={set_add_mandram}
          add_mandram={add_mandram}
          get_all_mandram_list={get_all_mandram_list}
          get_one_mandram={get_one_mandram}
          selected_district_id={selected_district_id}
          set_activate_key={set_activate_key}
          activate_key={activate_key}
        />
      </Col>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <Pagination
          showTotal={(total, range) => `Total ${countFormat(total)} Mandrams`}
          total={pagination?.total_count}
          showSizeChanger={true}
          current={current}
          hideOnSinglePage={false}
          onChange={(page, page_size) => fetch_data(page, page_size)}
        />
      </div>
      <AddMandramModal
        get_all_mandram_list={get_all_mandram_list}
        set_add_mandram={set_add_mandram}
        add_mandram={add_mandram}
        header_type={header_type}
        set_header_type={set_header_type}
      />
    </div>
  );
};

export default MandramVsg;
