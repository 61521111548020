import { Table } from "antd";
import React from "react";
import { useDynamicSelector } from "../../../../../services/redux";
import PrintHeader from "../../../common/print_header";
import { StyleSheet } from "@react-pdf/renderer";
import { getAmountFormatWithSymbol } from "../../../../../helpers/functions";
import { IS_TABLE_SCROLLABLE } from "../../../../../helpers/constants";

const UserGroupWiseReportList = ({ selected_date }) => {
  const { items: user_group_wise_items, loading: group_wise_loading } =
    useDynamicSelector("get_user_group_wise_summary");

  const styles = StyleSheet.create({
    tableCell: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  });

  // Calculate totals for each column
  const grand_total_enrolments = user_group_wise_items?.reduce(
    (acc, item) => acc + (item?.total_enrolments || 0),
    0
  );

  const grand_total_devotees = user_group_wise_items?.reduce(
    (acc, item) => acc + (item?.total_devotees || 0),
    0
  );

  const grand_total_cash_enrolments = user_group_wise_items?.reduce(
    (acc, item) => acc + (item?.cash_enrolments || 0),
    0
  );

  const grand_total_cash_amount = user_group_wise_items?.reduce(
    (acc, item) => acc + (item?.cash_amount || 0),
    0
  );

  const grand_total_card_enrolments = user_group_wise_items?.reduce(
    (acc, item) => acc + (item?.card_enrolments || 0),
    0
  );

  const grand_total_card_amount = user_group_wise_items?.reduce(
    (acc, item) => acc + (item?.card_amount || 0),
    0
  );

  const grand_total_upi_enrolments = user_group_wise_items?.reduce(
    (acc, item) => acc + (item?.upi_enrolments || 0),
    0
  );

  const grand_total_upi_amount = user_group_wise_items?.reduce(
    (acc, item) => acc + (item?.upi_amount || 0),
    0
  );

  const grand_total_online_enrolments = user_group_wise_items?.reduce(
    (acc, item) => acc + (item?.online_enrolments || 0),
    0
  );

  const grand_total_online_amount = user_group_wise_items?.reduce(
    (acc, item) => acc + (item?.online_amount || 0),
    0
  );

  const grand_total = user_group_wise_items?.reduce(
    (acc, item) => acc + (item?.sum || 0),
    0
  );

  const grand_total_dd_enrolments = user_group_wise_items?.reduce(
    (acc, item) => acc + (item?.dd_enrolments || 0),
    0
  );

  const grand_total_dd_amount = user_group_wise_items?.reduce(
    (acc, item) => acc + (item?.dd_amount || 0),
    0
  );

  const calculateColumnTotal = (columnName) => {
    return user_group_wise_items?.reduce(
      (acc, item) => acc + (item[columnName] || 0),
      0
    );
  };

  const columns = [
    {
      title: "User Group",
      key: "user_group",
      align: "left",
      fixed: "left",
      render: (data) => (data?.user_group_name ? data?.user_group_name : "-"),
    },
    {
      title: "Total Enrolments",
      align: "right",
      key: "total_enrolments",
      render: (data) => data?.total_enrolments,
    },
    {
      align: "right",
      title: "Total Devotees",
      key: "total_devotees",
      render: (data) => data?.total_devotees,
    },
    {
      align: "right",
      title: "Cash Enrolments",
      key: "cash_enrolments",
      render: (data) => data?.cash_enrolments,
    },
    {
      title: "Cash Amount",
      align: "right",
      key: "cash_amount",
      render: (data) => `₹${data?.cash_amount}`,
    },
    {
      title: "Card Amount",
      key: "card_amount",
      align: "right",
      render: (data) => `₹${data?.card_amount}`,
    },
    {
      title: "Card Enrolments",
      key: "card_enrolments",
      align: "right",
      render: (data) => data?.card_enrolments,
    },
    {
      title: "UPI Amount",
      key: "upi_amount",
      align: "right",
      render: (data) => `₹${data?.upi_amount}`,
    },
    {
      title: "UPI Enrolments",
      key: "upi_enrolments",
      align: "right",
      render: (data) => data?.upi_enrolments,
    },
    {
      title: "DD Amount",
      key: "dd_amount",
      align: "right",
      render: (data) => `₹${data?.dd_amount}`,
    },
    {
      title: "DD Enrolments",
      key: "dd_enrolments",
      align: "right",
      render: (data) => data?.dd_enrolments,
    },
    {
      title: "ONLINE Amount",
      key: "online_amount",
      align: "right",
      render: (data) => `₹${data?.online_amount}`,
    },
    {
      title: "ONLINE Enrolments",
      key: "online_enrolments",
      align: "right",
      render: (data) => data?.online_enrolments,
    },
    {
      title: "Total",
      key: "sum",
      align: "right",
      fixed: "right",
      width: "140px",
      render: (data) => `₹${data?.sum}`,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        margin: "auto",
        width: "98%",
        marginBottom: "20px",
      }}
    >
      <Table
        style={{ width: "96%", margin: "auto" }}
        data-name-table="district-wise-report"
        columns={columns}
        className="screen-table"
        loading={group_wise_loading}
        dataSource={user_group_wise_items ? [...user_group_wise_items] : []}
        pagination={false}
        scroll={{
          x: 1920,
          y: IS_TABLE_SCROLLABLE ? null : 300,
        }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <text style={{ fontWeight: "bold" }}>TOTAL</text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={1}>
                <text style={styles.tableCell}>
                  <b>{grand_total_enrolments}</b>
                </text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={2}>
                <text style={styles.tableCell}>
                  <b>{grand_total_devotees}</b>
                </text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={4}>
                <text style={styles.tableCell}>
                  <b> {grand_total_cash_enrolments}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <text style={styles.tableCell}>
                  <b>{getAmountFormatWithSymbol(grand_total_cash_amount)}</b>
                </text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={6}>
                <text style={styles.tableCell}>
                  <b> {getAmountFormatWithSymbol(grand_total_card_amount)}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <text style={styles.tableCell}>
                  <b>{grand_total_card_enrolments}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                <text style={styles.tableCell}>
                  <b>{getAmountFormatWithSymbol(grand_total_upi_amount)}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <text style={styles.tableCell}>
                  <b>{grand_total_upi_enrolments}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                <text style={styles.tableCell}>
                  <b>{getAmountFormatWithSymbol(grand_total_dd_amount)}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <text style={styles.tableCell}>
                  <b>{grand_total_dd_enrolments}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                <text style={styles.tableCell}>
                  <b>{getAmountFormatWithSymbol(grand_total_online_amount)}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <text style={styles.tableCell}>
                  <b>{grand_total_online_enrolments}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9}>
                <text style={styles.tableCell}>
                  <b>{getAmountFormatWithSymbol(grand_total)}</b>
                </text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />

      <div style={{ padding: "10px" }}>
        <div className="print-header" style={{ padding: "8px" }}>
          <PrintHeader />
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            USER GROUP WISE REPORT
          </h4>
          <h5
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {selected_date?.option?.least_date ===
            selected_date?.option?.last_date
              ? selected_date?.option?.least_date
              : `From ${selected_date?.option?.least_date} To ${selected_date?.option?.last_date}`}
          </h5>
        </div>

        <table
          className="table table-bordered print-table"
          style={{ width: "100%", marginTop: "10px", marginLeft: "-5px" }}
        >
          <thead style={{ width: "100%" }}>
            <tr>
              {columns.map((column) => (
                <th
                  className="header-style"
                  style={{ width: "100px" }}
                  key={column.key}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {user_group_wise_items?.map((report, index) => (
              <tr key={index}>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.user_group_name ? report?.user_group_name : "-"}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.total_enrolments}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.total_devotees}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.cash_enrolments}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.cash_amount)}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.card_amount)}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.card_enrolments}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.upi_amount)}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.upi_enrolments}
                </td>

                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.dd_amount)}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.dd_enrolments}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.online_amount)}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.online_enrolments}
                </td>

                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.sum)}
                </td>
              </tr>
            ))}
            <tr>
              <td className="body-style text-left-align">
                <b>Total</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b> {grand_total_enrolments}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_devotees}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_cash_enrolments}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total_cash_amount)}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total_card_amount)}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_card_enrolments}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total_upi_amount)}</b>
              </td>

              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_upi_enrolments}</b>
              </td>

              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total_dd_amount)}</b>
              </td>

              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_dd_enrolments}</b>
              </td>

              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total_online_amount)}</b>
              </td>

              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_online_enrolments}</b>
              </td>

              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total)}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <style>
  {`
    @media screen {
      .print-table {
        display: none;
      }
      .print-header {
        display: none;
      }
    }

    @media print {
      body {
        font-size: 10px; 
      }
      .screen-table {
        display: none !important;
      }
      .print-header {
        display: block !important;
      }

      @page {
        size: A4 landscape; /* Set landscape orientation */
        margin: 1cm 1cm 2cm;
      }

      body::before {
        content: counter(page);
        top: 0;
        right: 1cm;
      }
    }
  `}
</style>

    </div>
  );
};

export default UserGroupWiseReportList;
