import { gql } from "@apollo/client";

export const corpus_report_date_query = gql`
  query get_season_date_slot_list {
    corpus_report_date: get_season_date_slot_list {
      items {
        id
        season_id
        date
        date_string
      }
      error {
        message
        status_code
      }
      pagination {
        total_count
        page_number
        page_limit
      }
    }
  }
`;

export const geT_corpus_list_query=gql`
query get_corpus_list($filter: daily_transaction_summary_input) {
  get_corpus_list(filter: $filter) {
    items {
      receipt_no
      suffix
      devotte_count
      mandram_id
      district_name
      mandram_name
      mandram_code
      internal_code
      city_name
      city_code
      leader_name
      address
      aadhar_no
      id_type
      mobile
      adjusted_date_order
      date_order
    }
  }
}
`