import { gql } from "@apollo/client";

export const query_get_enrolment_details = gql`
  query get_enrolment_list(
    $pagination: pagination_input
    $filter: get_enrolment_list_filter_input
  ) {
    get_enrolment_list(pagination: $pagination, filter: $filter) {
      items {
        id
        sequence_count {
          count
          suffix
        }
        booking_id
        counter_id
        enrolled_on
        arrived_count
        status
        is_tickets_printed
         back_attachment_url
        front_attachment_url
        reprintable_ticket_list {
          ticket_number
        }
        booking {
          mandram {
            district {
              name
            }
          }
          booking_user_district {
            name
          }
          id
          date_slot_id
          time_slot {
            start_time_in_minutes
            end_time_in_minutes
          }
          price
          count
          confirmed_count
          waiting_count
          cancelled_count
          booking_reference
          booking_status
          booked_on
          booking_user {
            id
            mobile
            name
          }
          booking_user_id
          date_slot {
            id
            date
            start_time_in_minutes
            end_time_in_minutes
          }
          is_spot_booking
          is_vip
          is_prepaid
          mandram {
            id
            name
            code
          }
          payment {
            amount
            payment_mode
          }
        }
        counter {
          id
          name
          is_active
        }
        enrolled_by {
          name
          mobile
          email
          email
          system_user {
            id
            username
            name
            email
            mobile
          }
        }
        payment {
          amount
          payment_mode
          paid_on
          received_by {
            name
            mobile
            email
          }
        }
        is_tickets_printed
        error {
          status_code
          message
        }
      }
      error {
        status_code
        message
      }
      pagination {
        total_count
        page_number
        page_limit
      }
    }
  }
`;

export const query_get_booking_receipt = gql`
  query get_booking_receipt($custom: get_booking_receipt_input) {
    get_booking_receipt(custom: $custom) {
      id
      date_slot_id
      time_slot_id
      enrolment_list {
        sequence_count {
          count
        }
      }
      price
      is_prepaid
      count
      confirmed_count
      confirmed_count
      waiting_count
      cancelled_count
      booked_on
      booking_type
      booked_by {
        name
      }
      mandram_id
      status
      mandram {
        name
        code
        incharge_name
        incharge_mobile
        district {
          name
        }
      }
      booking_reference
      booking_user_id
      booking_status
      date_slot {
        date
        date_string
      }
      time_slot {
        id
        date_slot_id
        start_time_in_minutes
        end_time_in_minutes
        date_slot {
          start_time_in_minutes
          end_time_in_minutes
        }
      }
      booking_user {
        name
        mobile
        email
        address
        id_type
        id_number
      }
    }
  }
`;

export const mutation_cancel_booking = gql`
  mutation cancel_booking($id: String!) {
    cancel_booking(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_unlock_enrollment_ticket_printing = gql`
  mutation unlock_enrolment_ticket_printing($data: ticket_print_input) {
    unlock_enrolment_ticket_printing(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_billing_history_dashboard = gql`
  query get_billing_history_dashboard(
    $filter: get_billing_history_dashboard_filter_input
  ) {
    get_billing_history_dashboard(filter: $filter) {
      total_booked_count
      spot_booked_count
      arrived_count
      not_arrived_count
    }
  }
`;

export const mutation_update_enrolment = gql`
  mutation update_enrolment($id: String!, $data: update_enrolment_input) {
    update_enrolment(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
