import { Form, Select } from "antd";
import React from "react";

const GroupWiseBranch = () => {
  const group_items = [
    {
      id: "65ee6bc2-e810-4b49-bfe1-9d251bbaad0a",
      name: "ICICI",
    },
    {
      id: "8b31e49c-8488-44d6-924a-c6d49d1ee286",
      name: "Central Bank",
    },
  ];
  return (
    <Form.Item
      name="counter_group_id_list"
      label="Counter Group"
      rules={[{ required: true, message: "Please Select the Bank" }]}
    >
      <Select placeholder="Select Bank" allowClear showSearch>
        {group_items?.map((option, index) => (
          <Select.Option key={option.id} value={option.id}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default GroupWiseBranch;
