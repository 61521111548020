import React from 'react'
import { useDynamicSelector } from '../../../../../services/redux';
import { Typography } from 'antd';
import { getAmountFormatWithSymbol } from '../../../../../helpers/functions';

const OnlinePaymentList = () => {
    const {
        items: om_shakthi_payment_items,
        error: om_sakthi_payment_report_error,
      } = useDynamicSelector("get_om_sakthi_payment");
    
      const onlinePaymentItems = om_shakthi_payment_items?.filter(
        (item) => item.payment_mode === "online"
      );
    
      const totalAmount = onlinePaymentItems?.reduce(
        (acc, item) => acc + (item?.amount || 0),
        0
      );
  return (
    onlinePaymentItems?.length > 0 && (
        <div style={{ width: "100%" }}>
          <Typography style={{ fontWeight: "bold" }}>ONLINE</Typography>
          <table className="table table-bordered" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th className="header-style">SL.No</th>
                <th className="header-style">Receipt No</th>
                {/* <th className="header-style">Bank</th> */}
                <th className="header-style">Reference Number</th>
                <th className="header-style">Amount</th>
              </tr>
            </thead>
            <tbody>
              {onlinePaymentItems?.map((x, index) => (
                <tr key={index}>
                  <td className="body-style">{index + 1}</td>
                  <td className="body-style">{x.receipt_number}</td>{" "}
                  {/* <td className="body-style">{"-"}</td> */}
                  <td className="body-style">{x.ref_number||"-"}</td>
                  <td
                    className="body-style"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {getAmountFormatWithSymbol(x?.amount)}
                  </td>
                </tr>
              ))}
              <tr style={{ fontWeight: "bold" }}>
                <td className="body-style">Total</td>
                <td className="body-style"></td>
                <td className="body-style"></td>
                <td
                  className="body-style"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {getAmountFormatWithSymbol(totalAmount)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
  )
}

export default OnlinePaymentList