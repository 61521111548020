import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDynamicSelector } from "../../../../../services/redux";
import {
  getAmountFormatWithSymbol,
  total_amount_details,
} from "../../../../../helpers/functions";

const AmountDetails = () => {
  const [count, set_count] = useState();
  const { items: om_sakthi_report_items } = useDynamicSelector(
    "get_om_sakthi_summary"
  );

  useEffect(() => {
    set_count(total_amount_details(om_sakthi_report_items));
  }, [om_sakthi_report_items]);

  return (
    <div>
      {om_sakthi_report_items && (
        <div>
          <Typography style={{ fontWeight: "bold" }}>
            Pay Mode Wise Summary
          </Typography>

          <table className="table table-bordered" style={{ width: "100%" }}>
            <thead>
              <tr>
                {/* <th className="header-style">Cash Devotees</th> */}
                <th className="header-style">Cash Amount</th>
                {/* <th className="header-style">DD Devotees</th> */}
                <th className="header-style">DD Amount</th>
                {/* <th className="header-style">Card Devotees</th> */}
                <th className="header-style">Card Amount</th>
                {/* <th className="header-style">UPI Devotees</th> */}
                <th className="header-style">UPI Amount</th>
                <th className="header-style">Online Amount</th>
                {/* <th className="header-style">Total Devotees</th> */}
                <th className="header-style">Total Amount</th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="body-style">
                  {getAmountFormatWithSymbol(
                    om_sakthi_report_items
                      ? om_sakthi_report_items[0]?.total_cash_amount
                      : 0
                  )}
                </td>{" "}
                <td className="body-style">
                  {getAmountFormatWithSymbol(
                    om_sakthi_report_items
                      ? om_sakthi_report_items[0]?.total_dd_amount
                      : 0
                  )}
                </td>
                <td className="body-style">
                  {getAmountFormatWithSymbol(
                    om_sakthi_report_items
                      ? om_sakthi_report_items[0]?.total_card_amount
                      : 0
                  )}
                </td>
                <td className="body-style">
                  {getAmountFormatWithSymbol(
                    om_sakthi_report_items
                      ? om_sakthi_report_items[0]?.total_upi_amount
                      : 0
                  )}
                </td>
                <td className="body-style">
                  {getAmountFormatWithSymbol(
                    om_sakthi_report_items
                      ? om_sakthi_report_items[0]?.total_online_amount
                      : 0
                  )}
                </td>
                <td className="body-style">
                  {om_sakthi_report_items
                    ? getAmountFormatWithSymbol(
                        om_sakthi_report_items[0]?.total_amount
                      )
                    : 0}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AmountDetails;
