import { gql } from "@apollo/client";

export const query_user_group_list_users = gql`
query get_user_group_wise_list($sort:sort_input){
    get_user_group_wise_list(sort:$sort){
      items{
        id
        name
      }
    }
  }
`;

export const query_user_group_wise_summary_list = gql`
query get_user_group_wise_summary($filter: user_group_wise_summary_input){
  get_user_group_wise_summary(filter:$filter){
 items{
  user_group_name
  sum
  total_enrolments
  total_devotees
  cash_enrolments
  cash_amount
  dd_enrolments
  dd_amount
  card_enrolments
  card_amount
  upi_enrolments
  upi_amount
  online_enrolments
      online_amount
}
  }
}
`