import { Button, Card, Col, Form, Row } from 'antd'
import React from 'react'
import CounterGroupInput from '../counter_group_wise_report.jsx/inputs/counter_group_input'
import GroupWiseEntryFromDate from './group_wise_entry_from_date'
import GroupWiseEntryToDate from './group_wise_entry_to_date'
import GroupWiseBranch from './group_wise_branch'

const GroupWiseEntryForm = ({set_selected_date}) => {
    const [form]=Form.useForm()
  return (
    <Form
        id="search"
        form={form}
        // onFinish={handle_finish}
        // onValuesChange={on_change_values}
      >
        <Card
          style={{
            width: "96%",
            borderRadius: "5px",
            margin: "auto",
            backgroundColor: "#e7e7e7",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div style={{ marginBottom: "-15px" }}>
            <Row gutter={24}>
              <Col span={11}>
                <GroupWiseEntryFromDate
                  set_selected_date={set_selected_date}
                />
              </Col>
              <Col span={11}>
                <GroupWiseEntryToDate
                  set_selected_date={set_selected_date}
                />
              </Col>
              </Row>
              <Row gutter={24}>
              <Col span={11}>
                <GroupWiseBranch />
              </Col>
              <Col span={11}>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{
                      backgroundColor: "#014477",
                      color: "white",
                      borderRadius: "3px",
                    }}
                  >
                    {"Get"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Card>
      </Form>
  )
}

export default GroupWiseEntryForm
