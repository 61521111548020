import { gql } from "@apollo/client";

export const get_counter_user_wise_summary_list = gql`
  query get_counter_user_wise_summary(
    $filter: counter_user_wise_summary_filter_input
  ) {
    get_counter_user_wise_summary(filter: $filter) {
      items {
        user_name
        counter_name
        sum
        total_enrolments
        total_devotees
        cash_enrolments
        cash_amount
        dd_enrolments
        dd_amount
        card_enrolments
        card_amount
        upi_enrolments
        total_devotees
        upi_amount
        online_amount
      online_enrolments
      }
    }
  }
`;

export const user_group_wise_report_list = gql`
  query get_counter_group_wise_summary(
    $filter: counter_group_wise_summary_input
  ) {
    get_counter_group_wise_summary(filter: $filter) {
      items {
        upi_enrolments
        upi_amount
        card_amount
        cash_enrolments
        cash_amount
        dd_enrolments
        dd_amount
        online_amount
      online_enrolments
        card_enrolments
        counter_name
        sum
        total_enrolments
        total_devotees
      }
    }
  }
`;

export const enrolment_user_list = gql`
  query get_enrolment_user($sort: sort_input) {
    get_enrolment_user(sort: $sort) {
      items {
        name
        system_user {
          id
        }
      }
    }
  }
`;

export const counter_user_wise_report = gql`
  query get_counter_user_wise_summary(
    $filter: counter_user_wise_summary_filter_input
  ) {
    get_counter_user_wise_summary(filter: $filter) {
      items {
        counter_name
        sum
        total_enrolments
        total_enrolments
        total_devotees
        cash_enrolments
        cash_amount
        dd_enrolments
        dd_amount
        card_enrolments
        upi_amount
        upi_enrolments
        online_amount
      online_enrolments
        card_amount
      }
    }
  }
`;

export const user_counter_wise_date_slot_list = gql`
query get_season_date_slot_list($sort: sort_input) {
  user_counter_wise_date_slot_list: get_season_date_slot_list(
    sort: $sort
  ) {
    items {
      id
      date_string
      date
    }
  }
}
`

export const user_counter_wise_report = gql`
query get_user_counter_wise_summary($filter: user_counter_wise_summary_filter_input){
  get_user_counter_wise_summary(filter:$filter){
    items{
      user_name
      counter_name
      sum
      total_enrolments
      total_devotees
      cash_enrolments
      cash_amount
      dd_enrolments
      dd_amount
      card_enrolments
      card_amount
      upi_enrolments
      upi_amount
        online_enrolments
      online_amount
    }
    error{
      status_code
      message
    }
  }
  }
`;