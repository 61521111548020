import React, { useEffect } from "react";
import { Form, Row } from "antd";
import {
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
} from "@services/redux";
import { useDispatch } from "react-redux";
import {
  mutation_create_district,
  mutation_update_district,
} from "../../../../services/redux/slices/graphql/graphql_district";
import { District } from "./district_form_components/district";
import { Admin } from "./district_form_components/admin";
import InputIsActive from "./form_inputs/input_is_active";
import { toast } from "react-toastify";
import { retrieveItem } from "../../../../helpers/functions";

const DistrictForm = (props) => {
  const { header_type, set_edit_modal_visible, get_all_districts } = props;
  const role = retrieveItem("role");

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    id,
    name,
    tamil_name,
    code,
    group_id,
    is_vip,
    is_foreign,
    extra_allowed_percentage,
    order_number,
    is_active,
    admin,
    pos,
    available_slot_list,
  } = useDynamicSelector("get_district");

  const pos_id_list = pos?.map((item) => item.id);

  const { status: create_district_status, error: create_district_error } =
    useDynamicSelector("create_district");

  const { status: update_district_status, error: update_district_error } =
    useDynamicSelector("update_district");

  const { status: delete_district_status, error: delete_district_error } =
    useDynamicSelector("delete_district");

  const is_visible = role === "Super Admin" || role === "MASM Admin";

  const create_district = (values) => {
    let keys = [{ key: "create_district", loading: true }];
    let query = mutation_create_district;
    const { district_admin, ...restValues } = values;
    let variable = {
      data: {
        ...restValues,
        district_admin: {
          name: district_admin?.name,
          email: district_admin?.email,
          mobile: district_admin?.mobile,
          is_active: district_admin?.is_active,
          system_user: {
            password: "Masm@123Om",
            username: district_admin?.mobile,
          },
        },
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const update_district = (values) => {
    let keys = [{ key: "update_district", loading: true }];
    let query = mutation_update_district;
    let variable = {
      id,
      data: {
        ...values,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const on_finish = (values) => {
    console.log("sssss", values);

    if (header_type === "add_district") {
      create_district(values);
    } else {
      let update_values = {
        name: values?.name,
        code: values.code,
        extra_allowed_percentage: values?.extra_allowed_percentage,
        tamil_name: values?.tamil_name,
        group_id: values?.group_id,
        is_vip: values?.is_vip,
        is_foreign: values?.is_foreign,
        is_active: values?.is_active,
        available_slot_list: values?.available_slot_list,
        district_admin: {
          name: values.district_admin.name,
          email: values.district_admin.email,
          mobile: values.district_admin.mobile,
          is_active: values.district_admin.is_active,
          system_user: {
            // password: values.district_admin.system_user.password,
            password: "123456",
            // user_name: values.district_admin.user_name,
            username: values.district_admin.mobile,
          },
        },
        pos_id_list: values.pos_id_list,
      };
      update_district(update_values);
    }
  };

  useEffect(() => {
    if (create_district_status === "success") {
      toast.success("District created");
      dispatch(dynamicClear("create_district"));
      get_all_districts();
      set_edit_modal_visible(false);
    } else if (create_district_error?.message === "Username already exist") {
      toast.error("Mobile Number already exist");
      dispatch(dynamicClear("create_district"));
    } else if (
      create_district_error?.message === "Mobile number already exist"
    ) {
      toast.error("Mobile number already exist");
      dispatch(dynamicClear("create_district"));
    } else if (create_district_error?.message) {
      toast.error("Something went wrong");
      dispatch(dynamicClear("create_district"));
    } else if (update_district_status === "success") {
      toast.success("District updated");
      dispatch(dynamicClear("update_district"));
      get_all_districts();
      set_edit_modal_visible(false);
    } else if (update_district_error?.message === "Username already exist") {
      toast.error("Mobile Number already exist");
      dispatch(dynamicClear("update_district"));
    } else if (
      update_district_error?.message === "Mobile number already exist"
    ) {
      toast.error("Mobile number already exist");
      dispatch(dynamicClear("update_district"));
    } else if (update_district_error?.message) {
      toast.error("Something went wrong");
      dispatch(dynamicClear("update_district"));
      set_edit_modal_visible(false);
    }
    if (delete_district_status === "success") {
      toast.success("District deleted");
      dispatch(dynamicClear("delete_district"));
      get_all_districts();
      set_edit_modal_visible(false);
    } else if (delete_district_error?.message) {
      toast.error("Something went wrong");
      dispatch(dynamicClear("delete_district"));
      // set_edit_modal_visible(false);
    }
  }, [
    create_district_status,
    create_district_error,
    update_district_status,
    update_district_error,
    delete_district_status,
    delete_district_error,
  ]);

  useEffect(() => {
    if (header_type === "edit_district") {
      form.setFieldsValue({
        id,
        name,
        tamil_name,
        code,
        group_id,
        is_vip,
        is_foreign,
        extra_allowed_percentage,
        order_number,
        is_active,
        district_admin: {
          ...admin,
          // system_user: {
          //   password: admin?.password,
          //   username: admin?.system_user?.username,
          // },
        },
        // pos_id_list: pos_id_list,
        available_slot_list: available_slot_list,
      });
      form.setFieldsValue({
        district_admin: {
          is_active: admin?.system_user?.is_active,
        },
      });
    } else {
      form.resetFields();
    }
  }, [header_type, id]);
  return (
    <Form
      layout="horizontal"
      colon={false}
      labelAlign="left"
      id="district_form"
      onFinish={on_finish}
      form={form}
      autoComplete="off"
      labelCol={{
        span: 10,
      }}
    >
      <div
        style={{
          gap: "12px",
        }}
      >
        <District {...props} />
        <Admin {...props} />
        <Row style={{ width: "500px" }}>{is_visible && <InputIsActive />}</Row>
        {/* <PosCard /> */}
      </div>
    </Form>
  );
};

export default DistrictForm;
