import React from "react";
import { Typography } from "antd";

const Travelers = () => {
  const { Text } = Typography;
  return (
    <div style={{ alignItems: "center", padding: "20px" }}>
      <div>
        <Text
          style={{
            color: "black",
            textAlign: "justify",
            fontWeight: "bold",
            fontSize:"20px"
          }}
        >
          பயணம் வருவோர்க்கு:
        </Text>
      </div>
      <div style={{marginTop:"10px"}}>
        <Text
          style={{
            color: "black",
            textAlign: "justify",
            fontSize:"14px"
          }}
        >
          பயணம் துவங்குபவர்கள், சற்றுத் தூரம், நடந்து வர வேண்டும். உந்து வண்டி,
          பேருந்து, புகைவண்டி மார்க்கமாகவோ வரலாம். தனி - உந்துவண்டியில்
          வருபவர்கள் வண்டியில் அன்னையின் திருவுருவப்படத்தினைப் பொருத்தி
          மாலையிட்டு, ஆராதனைசெய்து வண்டியைச் சுற்றி வந்து, எலுமிச்சம் பழம்
          பிழிந்து ஊர்தியில் ஏறவேண்டும். முடிந்த வரையில் இரவு நேரங்களில் பயணம்
          தவிர்க்கப்பட வேண்டும். உந்து வண்டியில் வருபவர்களும் சக்தி மாலை
          செலுத்தியபின் இல்லத்திற்கு செல்பவர்களும் மணிக்கு 50 கி.மீ வேகத்திற்கு
          மிகப்படாமல் செய்யவேண்டும்.
        </Text>
      </div>
    </div>
  );
};
export default Travelers;
