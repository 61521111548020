import React from "react";
import { Col } from "antd";
import CustomPageTitle from "../components/common/custom_page_title";
import { useDynamicSelector } from "../../services/redux";

import EnrolmentSession from "../components/enrolment_session/enrolment_session";
import Enrolment from "../components/enroll/enrolment";

const Enroll = () => {
  const { enrolment_status } = useDynamicSelector("enrolment_status");
  // console.log("enrolment_status",enrolment_status)

  return (
    <Col>
      <CustomPageTitle title="ENROLL" />
      <EnrolmentSession />
      {enrolment_status === "open" && <Enrolment />}
    </Col>
  );
};

export default Enroll;
