import React from "react";
import { Typography } from "antd";
import { Box } from "native-base";

const OriginalMantra = () => {
  const { Text } = Typography;
  return (
    <div style={{ alignItems: "center", padding: "20px" }}>
      <Box alignItems={"center"}>
        <Text
          style={{
            color: "black",
            textAlign: "justify",
            fontWeight: "bold",
            fontSize: "30px",
          }}
        >
          மூல மந்திரம்
        </Text>
      </Box>
      <Box >
        <div style={{ marginTop: "10px" }}>
          <Text
            style={{
              color: "black",
              textAlign: "start",
              fontSize: "14px",
            }}
          >
            ஓம் சக்தியே! பராசக்தியே!
          </Text>
        </div>
        <div style={{ marginTop: "10px" }}>
          <Text
            style={{
              color: "black",
              textAlign: "start",
              fontSize: "14px",
            }}
          >
            ஓம் சக்தியே! ஆதிபராசக்தியே!
          </Text>
        </div>
        <div style={{ marginTop: "10px" }}>
          <Text
            style={{
              color: "black",
              textAlign: "start",
              fontSize: "14px",
            }}
          >
            ஓம் சக்தியே! மருவூர் அரசியே!
          </Text>
        </div>
        <div style={{ marginTop: "10px" }}>
          <Text
            style={{
              color: "black",
              textAlign: "start",
              fontSize: "14px",
            }}
          >
            ஓம் சக்தியே! ஓம் விநாயகா!
          </Text>
        </div>
        <div style={{ marginTop: "10px" }}>
          <Text
            style={{
              color: "black",
              textAlign: "start",
              fontSize: "14px",
            }}
          >
            ஓம் சக்தியே! ஓம் காமாட்சியே!
          </Text>
        </div>
        <div style={{ marginTop: "10px" }}>
          <Text
            style={{
              color: "black",
              textAlign: "start",
              fontSize: "14px",
            }}
          >
            ஓம் சக்தியே! ஓம் பங்காரு காமாட்சியே!
          </Text>
        </div>
      </Box>
    </div>
  );
};
export default OriginalMantra;
