import { Button, Col, Form, Popconfirm, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  bulk_mandram_update,
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  get_mandram_list_query,
  useDynamicSelector,
} from "../../../services/redux";
import { useTranslation } from "react-i18next";
import { CustomSearchBox } from "../common/custom_search_box";
import {
  get_district_details_query,
  sub_district_details_query,
} from "../../../services/redux/slices/graphql/graphql_district";
import { useDispatch } from "react-redux";
import Title from "antd/lib/typography/Title";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { filter } from "lodash";
import MandramRenewalFileUpload from "./mandram_renewal_file_upload";

const MandramRenewalFilterForm = (props) => {
  const { set_district_id, set_sub_district_id } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id: params_district_id } = useParams();

  const district_id = Form.useWatch("district_id", form);
  const sub_district_id = Form.useWatch("sub_district_id", form);

  console.log("testsssssss",district_id,sub_district_id)

  const { pagination } = useDynamicSelector("get_mandram_list");
  const { items: get_districts } = useDynamicSelector("get_district_values");
  const { items: get_sub_districts } = useDynamicSelector(
    "get_sub_district_list"
  );

  const { key: activate_key } = useDynamicSelector("activate_key");
  const {
    loading: bulk_update_loading,
    status: bulk_update_status,
    error: bulk_update_error,
  } = useDynamicSelector("update_many_mandram_status");

  const [search_string, set_search_string] = useState("");

  useEffect(() => {
    get_district_list();
    dispatch(dynamicClear("get_sub_district_list"));
  }, []);

  useEffect(() => {
    if (bulk_update_status === "success") {
      get_mandram_list();
      toast.success(t("bulk_updated_successfully"));
      dispatch(dynamicClear("update_many_mandram_status"));
      dispatch(dynamicSet("activate_key", { key: [] }));
    } else if (bulk_update_error) {
      dispatch(dynamicSet("activate_key", { key: [] }));
    }
  }, [bulk_update_status, bulk_update_error]);

  useEffect(() => {
    if (params_district_id) {
      form.setFieldsValue({ district_id: params_district_id });
      get_sub_district_list(params_district_id);
      get_mandram_list("", null);
    }
  }, [params_district_id]);

  useEffect(() => {
    if (
      (district_id === undefined && sub_district_id === null) ||
      sub_district_id === undefined ||
      district_id
    ) {
      get_mandram_list("", null);
    }
  }, [district_id, sub_district_id]);

  const handle_bulk_active = () => {
    update_many_mandram(activate_key);
  };

  const get_district_list = () => {
    let key = [{ key: "get_district_values", loading: true }];
    let query = get_district_details_query;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_sub_district_list = (id) => {
    let key = [{ key: "get_sub_district_list", loading: true }];
    let query = sub_district_details_query;
    let variables = {
      filter: { district_id: id },
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const update_many_mandram = (id_list) => {
    let keys = [{ key: "update_many_mandram_status", loading: true }];
    dispatch(
      dynamicRequest(keys, bulk_mandram_update, {
        data: {
          id_list: id_list,
          activation_status: "activated",
        },
      })
    );
  };

  const get_mandram_list = (search_string = "", get_sub_district_id) => {
    let key = [{ key: "get_mandram_list", loading: true }];
    let query = get_mandram_list_query;
  
    // Determine if the sub_district_id should be set to null and show_unset_mandram to true
    const isUnsetMandram = sub_district_id === "show_unset_mandram" || get_sub_district_id === "show_unset_mandram";
  
    let variables = {
      filter: {
        district_id: district_id || params_district_id,
        sub_district_id: isUnsetMandram ? null : sub_district_id || get_sub_district_id,
        show_unset_mandram: isUnsetMandram, // Add this flag for unset mandram
        search_string: search_string,
      },
      sort: {
        field: "internal_code",
        order: "asc",
      },
      pagination: {
        page_number: 1,
        page_limit: 10,
      },
    };
  
    dispatch(dynamicRequest(key, query, variables));
  };

  const on_search = (value) => {
    get_mandram_list(value);
  };

  const handle_values_change = (value, values) => {
    console.log("valuevaluevalue", value, values);
    if (value?.district_id) {
      set_district_id(value?.district_id);
      get_sub_district_list(value?.district_id);
    }
    if (value?.sub_district_id&&value?.sub_district_id!="show_unset_mandram") {
      set_sub_district_id(value?.sub_district_id);
      get_mandram_list("", value?.sub_district_id);
    }
    if (value?.sub_district_id === "show_unset_mandram") {
      set_sub_district_id("show_unset_mandram");
      get_mandram_list("", "show_unset_mandram");
    }
    if (values?.district_id === undefined || values?.district_id === null) {
      form.setFieldsValue({ sub_district_id: null });
      dispatch(dynamicClear("get_sub_district_list"));
      set_district_id("");
      set_sub_district_id("");
    }
    if (
      values?.sub_district_id === undefined ||
      values?.sub_district_id === null
    ) {
      set_sub_district_id("");
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <Title level={4}>{t("mandram_renewal_title")}</Title>
          <Title level={5} style={{ width: "170px" }}>
            Total Mandrams ({pagination?.total_count || 0})
          </Title>
        </Col>
      </Row>
      <Row
        justify={"space-between"}
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Form layout="inline" onValuesChange={handle_values_change} form={form}>
          <Form.Item name={"district_id"}>
            <Select
              // options={get_districts}
              // fieldNames={{ label: "name", value: "id" }}
              style={{ width: "200px" }}
              placeholder={"Districts"}
              showSearch
              allowClear
              filterOption={(inputValue, option) =>
                (option?.children ?? "")
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            >
              {get_districts?.map((district) => (
                <Select.Option key={district?.id} value={district?.id}>
                  {`${district.name} - ${district.code}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={"sub_district_id"}>
            <Select
              // options={get_sub_districts}
              // fieldNames={{ label: "name", value: "id" }}
              style={{ width: "200px" }}
              placeholder={"Sub Districts"}
              showSearch
              allowClear
              filterOption={(inputValue, option) =>
                (option?.children ?? "")
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            >
              {get_sub_districts
                ?.concat([
                  { id: "show_unset_mandram", name: "Unset Mandram", code: "" },
                ])
                ?.map((sub_district) => (
                  <Select.Option
                    key={sub_district?.id}
                    value={sub_district?.id}
                  >
                    {sub_district?.id === "show_unset_mandram"
                      ? sub_district.name
                      : `${sub_district.name} - ${sub_district.code}`}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
        {/* <MandramRenewalFileUpload/> */}
        <Row gutter={10}>
          <Col>
            <Popconfirm
              title={
                activate_key?.length > 1
                  ? "Are you sure you want to activate selected items?"
                  : "Are you sure you want to activate selected item"
              }
              onConfirm={handle_bulk_active}
              okText="Yes"
              cancelText="No"
            >
              <Button
                loading={bulk_update_loading}
                disabled={
                  activate_key === undefined && activate_key?.length < 0
                    ? true
                    : false
                }
                type="primary"
              >
                {t("bulk_activate")}
              </Button>
            </Popconfirm>
          </Col>
          <Col>
            <CustomSearchBox
              placeholder={"Search Mandram"}
              on_search={on_search}
            />
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default MandramRenewalFilterForm;
