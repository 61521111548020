import { Checkbox, Form, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { dynamicSet, useDynamicSelector } from "../../../services/redux";
import { useDispatch } from "react-redux";

const DistrictUserCheckbox = (props) => {
  const { option, index, handle_on_change } = props;
  const [is_checked, set_is_checked] = useState(option.is_active);
  const district_data = useDynamicSelector("district_user_initial_data");

  console.log("is_checked",is_checked);
  

  const dispatch = useDispatch();
  const handle_checkbox_change = (e) => {
    const new_checked_value = e.target.checked;
    set_is_checked(new_checked_value);
    if (new_checked_value) {
      dispatch(
        dynamicSet("district_user_initial_data", {
          initial_values:
            district_data?.initial_values?.length > 0
              ? district_data?.initial_values?.concat([option.id])
              : [option.id],
        })
      );
    } else {
      let filter_active_mandram_ids = district_data?.initial_values?.filter(
        (list) => list !== option?.id
      );
      // Optionally handle uncheck scenario (e.g., clear or modify data)
      dispatch(
        dynamicSet("district_user_initial_data", {
          initial_values: filter_active_mandram_ids,
        })
      );
    }

    // Trigger any additional change handlers
    handle_on_change(e, index, option.id);
  };

  useEffect(() => {
    set_is_checked(option.is_active);
  }, [option.is_active]);

  return (
      <Form.Item
        key={option.id}
        name={`mandram-${option.id}`}
        valuePropName="checked"
      >
        <Checkbox onChange={handle_checkbox_change}  checked={is_checked}>
          {option?.code}/{option?.name}
        </Checkbox>
      </Form.Item>
  );
};
export default DistrictUserCheckbox;
