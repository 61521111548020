import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDynamicSelector } from "../../../../../services/redux";

const NoOfDevotees = (props) => {
  const {
    set_no_of_devotees,
    no_of_devotees,
    set_total_amount,
    price,
    arrived_count,
    allowed_devotee,
    set_visible,
    excess_devotee_count,
    form,
  } = props;

  const { is_vip, payment, count, enrolment_list, yet_to_enroll_count } =
    useDynamicSelector("booking_details");
  const [total_arrived, set_total_arrived] = useState(0);

  const irumudi_count = Form.useWatch("no_of_devotees");

  useEffect(() => {
    const total = enrolment_list.reduce(
      (sum, enrolment) => sum + enrolment.arrived_count,
      0
    );
    set_total_arrived(total);
  }, [enrolment_list]);

  useEffect(() => {
    if (!no_of_devotees) return;
    const total_devotees = parseInt(no_of_devotees, 10) + arrived_count;
    let _irumudi_count = parseInt(irumudi_count, 10);
    if (is_vip && yet_to_enroll_count < _irumudi_count) {
      toast.error("Extra devotees cannot be enrolled");
    } else {
      set_visible(true);
    }
    if (!is_vip) {
      if (total_devotees > allowed_devotee) {
        toast.error("Devotees count exceeded");
        set_visible(false);
        return;
      } else if (
        total_devotees <= allowed_devotee &&
        (excess_devotee_count === 0 || total_devotees >= 1)
      ) {
        set_visible(true);
      } else {
        toast.error("Devotees count exceeded");
        set_visible(false);
      }
    }
  }, [
    no_of_devotees,
    arrived_count,
    allowed_devotee,
    excess_devotee_count,
    count,
    is_vip,
  ]);

  // useEffect(() => {
  //   if (!no_of_devotees) return;
  //   const total_devotees = parseInt(no_of_devotees, 10) + arrived_count;
  //   const extra_allowed = count - total_arrived;
  //   if (is_vip &&total_arrived > count) {
  //     toast.error(`VIP enrollment allows only ${extra_allowed} extra devotees`);
  //   } else if (is_vip && total_devotees > count) {
  //     toast.error("VIP enrollment does not allow extra devotees");
  //   } else if (total_devotees > allowed_devotee) {
  //     toast.error("Devotees count exceeded");
  //     set_visible(false);
  //   } else {
  //     const is_devotees_within_limit =
  //       total_devotees <= allowed_devotee &&
  //       (excess_devotee_count === 0 || total_devotees >= 1);

  //     set_visible(is_devotees_within_limit);

  //     if (!is_devotees_within_limit) {
  //       toast.error("Devotees count exceeded");
  //     }
  //   }
  // }, [
  //   no_of_devotees,
  //   arrived_count,
  //   allowed_devotee,
  //   excess_devotee_count,
  //   count,
  //   is_vip,
  //   total_arrived,
  // ]);

  const on_change = (e) => {
    const value = e.target.value;

    set_no_of_devotees(value);
    const total = price * value;
    set_total_amount(total);
  };

  const on_key_press = (e) => {
    if (e.key === "-") {
      e.preventDefault();
    }
  };
  return (
    <Form.Item
      label={
        <span style={{ fontWeight: "bold", fontSize: "16px" }}>
          Arrived Devotees Count
        </span>
      }
      name="no_of_devotees"
      rules={[
        {
          required: true,
          message: "Please enter the number of devotees!",
        },
      ]}
      labelCol={{ span: 24 }}
    >
      <Input
        style={{ width: "80%", border: "1px solid #b0b0b0" }}
        type="Number"
        autoFocus
        onChange={on_change}
        onKeyPress={on_key_press}
        min={1}
      />
    </Form.Item>
  );
};

export default NoOfDevotees;
