import React, { useEffect, useState } from "react";
import {
  dynamicClear,
  dynamicRequest,
  query_asynchronous_check_request,
  useDynamicSelector,
} from "../../../../services/redux";
import { Button, Form, Modal, Typography } from "antd";
import { IoMdClose } from "react-icons/io";
import EnrollConfirmationDetailsCard from "../enroll_confirmation_details_card";
import { useTranslation } from "react-i18next";
import CaptureIdProof from "../search_enroll_details/capture_id_proof";
import { create_enroll_booking_query } from "../../../../services/redux/slices/graphql/graphql_enroll_booking";
import { useDispatch } from "react-redux";
import moment from "moment";
import toast from "react-hot-toast";
import TokenPrint from "./token_print";
import { retrieveItem } from "../../../../helpers/functions";
let interval_id;

const CreateEnrolment = ({
  open_enrol_confirmation,
  form,
  set_open_enrol_confirmation,
  payment_mode,
  spot_enrol_button_ref,
  front_url,
  set_front_url,
  back_url,
  set_back_url,
  receipt_form,
  get_enroll_booking_list,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user_name=retrieveItem("system_user");
  const enrol_detail = useDynamicSelector("enrol_detail");
  const irumudi_count = Form.useWatch("no_of_devotees", form);
  const { id: asynchronous_request_id } = useDynamicSelector("enroll_booking");
  const [enrolled_id, set_enrolled_id] = useState("");
  const [is_enrolling, set_is_enrolling] = useState(false);
  const [is_print_token_modal_visible, set_is_print_token_modal_visible] =
    useState(false);

  const { id, mandram, confirmed_count, waiting_count, is_vip } =
    useDynamicSelector("booking_details");

  const { status: asynchronous_status, response } = useDynamicSelector(
    "asynchronous_check_request"
  );

  const total_booked_count = confirmed_count + waiting_count;
  const is_id_proof_required =
    is_vip || (total_booked_count >= 58 && irumudi_count >= 58);

  useEffect(() => {
    if (asynchronous_request_id && is_enrolling) {
      dispatch(dynamicClear("enroll_booking"));
      interval_id = setInterval(() => check_asynchronous_request(), 5000);
    }
  }, [asynchronous_request_id]);

  useEffect(() => {
    if (is_enrolling) {
      if (asynchronous_status === "completed") {
        const created_enrolment_detail = JSON.parse(response);
        if (created_enrolment_detail.status === "enrolled") {
          get_enroll_booking_list();
          set_enrolled_id(created_enrolment_detail?.id);
          clearInterval(interval_id);
          set_is_enrolling(false);
          set_open_enrol_confirmation(false);
          set_is_print_token_modal_visible(true);
          dispatch(dynamicClear("asynchronous_check_request"));
        }
      } else if (asynchronous_status === "failed") {
        get_enroll_booking_list();
        clearInterval(interval_id);
        set_is_enrolling(false);
        set_open_enrol_confirmation(false);
        toast.error("Enrolment failed");
        dispatch(dynamicClear("asynchronous_check_request"));
      }
    }
  }, [asynchronous_status]);

  const check_asynchronous_request = () => {
    let key = [{ key: "asynchronous_check_request", loading: true }];
    let query = query_asynchronous_check_request;
    let variables = {
      id: asynchronous_request_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_enrolment = () => {
    if (is_id_proof_required && !front_url&&user_name!=="addn") {
      set_open_enrol_confirmation(false);
      toast.error("Please attach ID Proof before proceeding.");
      return;
    }
    set_is_enrolling(true);
    set_enrolled_id("");
    let key = [{ key: "enroll_booking", loading: true }];
    let query = create_enroll_booking_query;
    let variables = {
      data: {
        booking_id: id,
        arrived_count: parseInt(irumudi_count, 10),
        payment_mode: payment_mode,
        dd_reference: enrol_detail?.reference_number,
        dd_bank_id: enrol_detail?.bank,
        dd_datetime: moment(enrol_detail?.date).format("YYYY-MM-DDTHH:mm:ssZ"),
        dd_amount: enrol_detail?.dd_amount,
        pos_receipt: enrol_detail?.upi_reference,
        front_attachment_url: is_id_proof_required ? front_url : undefined,
        back_attachment_url: is_id_proof_required
          ? back_url
            ? back_url
            : undefined
          : undefined,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  return (
    <>
      <Modal
        title="Confirmation"
        open={open_enrol_confirmation}
        onCancel={() => set_open_enrol_confirmation(false)}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          <Button
            style={{
              backgroundColor: "#f96451",
              color: "white",
              border: "transparent",
            }}
            onClick={() => set_open_enrol_confirmation(false)}
          >
            {t("no")}
          </Button>,
          <Button
            style={{
              color: "white",
              border: "transparent",
              backgroundColor: "#168f92",
            }}
            onClick={() => handle_enrolment()}
            loading={is_enrolling}
          >
            {t("yes")}
          </Button>,
        ]}
      >
        <Typography style={{ marginBottom: "15px" }}>
          Do you want to enroll for the following details ?
        </Typography>
        <EnrollConfirmationDetailsCard title={"Code"} value={mandram?.code} />
        <EnrollConfirmationDetailsCard
          title={"Mandram"}
          value={mandram?.name}
        />
        <EnrollConfirmationDetailsCard
          title={"Devotees"}
          value={enrol_detail?.no_of_devotees}
        />
      </Modal>

      <TokenPrint
        is_print_token_modal_visible={is_print_token_modal_visible}
        set_is_print_token_modal_visible={set_is_print_token_modal_visible}
        spot_enrol_button_ref={spot_enrol_button_ref}
        enrolled_id={enrolled_id}
        set_front_url={set_front_url}
        set_back_url={set_back_url}
        receipt_form={receipt_form}
      />
    </>
  );
};

export default CreateEnrolment;
