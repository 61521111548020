import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { toUpper, upperCase } from "lodash";
import { useTranslation } from "react-i18next";
import SwamiImage from "@assets/icons/adhiparashathi_image.png";

const CounterGroupWisePDF = ({ counter_group_wise_items }) => {
  const [amount, set_amount] = useState();
  useEffect(() => {
    let total = 0;
    counter_group_wise_items?.forEach((item) => {
      total += parseFloat(item?.sum) || 0;
    });
    set_amount(total);
  }, [counter_group_wise_items]);

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "white",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    table: {
      display: "table",
      width: "auto",
    },
    tableRow: {
      flexDirection: "row",
    },
    table_first_cell: {
      padding: 4,
      flex: 1,
      textAlign: "left",
      fontSize: "10px",
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderLeftWidth: 1,
      borderRightWidth: 0,
    },
    table_last_cell: {
      padding: 4,
      flex: 1,
      textAlign: "center",
      fontSize: "10px",
      borderTopWidth: 1,
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },
    table_last_index_first_cell: {
      padding: 4,
      flex: 1,
      textAlign: "center",
      fontSize: "10px",
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderRightWidth: 1,
    },
    table_last_index_name_last_cell: {
      padding: 4,
      flex: 1,
      textAlign: "left",
      fontSize: "10px",
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderRightWidth: 1,
    },
    table_last_index_cell: {
      padding: 4,
      flex: 1,
      textAlign: "center",
      fontSize: "10px",
      borderTopWidth: 1,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      // borderBottomWidth: 1,
    },
    table_last_index_last_cell: {
      padding: 4,
      flex: 1,
      textAlign: "center",
      fontSize: "10px",
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },
    tableCell: {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderLeftWidth: 1,
      borderRightWidth: 0,
      padding: 4,
      flex: 1,
      textAlign: "center",
      fontSize: "10px",
    },
    tableCellName: {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderLeftWidth: 1,
      borderRightWidth: 0,
      padding: 4,
      flex: 1,
      textAlign: "left",
      fontSize: "10px",
    },
    tableColHeader: {
      borderRightWidth: 1,
      borderTopWidth: 1,
      backgroundColor: "#f0f0f0",
      padding: 3,
      flex: 1,
      textAlign: "center",
      fontSize: "10px",
    },
    tableColHeader_first_header: {
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      backgroundColor: "#f0f0f0",
      padding: 3,
      flex: 1,
      textAlign: "center",
      fontSize: "10px",
    },
    table_header: {
      display: "flex",
      margin: "5px",
      fontWeight: "bold",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "10px",
    },
    header_content: {
      fontWeight: "600",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "10px",
    },
    image: {
      width: "30px",
      height: "40px",
    },
    full_header_content: {
      marginTop: "-20px",
      display: "flex",
      alignContent: "center",
      justifyContent: "space-evenly",
    },
    pageNumber: {
      position: "absolute",
      bottom: 10,
      left: 0,
      right: 0,
      textAlign: "center",
      fontSize: 10,
    },
  });

  const { t } = useTranslation();

  const calculateColumnTotal = (columnKey) => {
    return counter_group_wise_items
      ? counter_group_wise_items.reduce(
          (sum, record) => sum + (record[columnKey] || 0),
          0
        )
      : 0;
  };

  return (
    <Document fileName={`counter-group-wise-report.pdf`}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image style={styles.image} src={SwamiImage}></Image>

          <View style={styles.flexContainer}>
            <View style={styles.full_header_content}>
              <Text style={styles.header_content}>{t("om_shakthi")}</Text>
              <Text style={styles.header_content}>{t("masm")} </Text>
              <Text style={styles.header_content}>
                {t("adhiparashakthi_siddhar_peedam")}
              </Text>
            </View>
          </View>

          <View></View>
          <Text style={styles.table_header}>
            {toUpper("counter group wise report")}
          </Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader_first_header}>
                Counter Group
              </Text>
              <Text style={styles.tableColHeader_first_header}>
                Total Enrolments
              </Text>
              <Text style={styles.tableColHeader}>Devotees</Text>
              <Text style={styles.tableColHeader}>Cash Enrolments</Text>
              <Text style={styles.tableColHeader}>Cash Amount</Text>
              <Text style={styles.tableColHeader}>DD Enrolments</Text>
              <Text style={styles.tableColHeader}>DD Amount</Text>
              <Text style={styles.tableColHeader}>Card Enrolments</Text>
              <Text style={styles.tableColHeader}>Card Amount</Text>
              <Text style={styles.tableColHeader}>UPI Amount</Text>
              <Text style={styles.tableColHeader}>UPI Enrollments</Text>
              <Text style={styles.tableColHeader}>ONLINE Amount</Text>
              <Text style={styles.tableColHeader}>ONLINE Enrollments</Text>
              <Text style={styles.tableColHeader}>Total Amount</Text>
            </View>
            {counter_group_wise_items?.map((item, index) => (
              <View
                style={styles.tableRow}
                key={index}
                wrap={index !== 0 ? "wrap" : "nowrap"}
              >
                <Text
                  style={
                    index === counter_group_wise_items?.length - 1
                      ? styles.table_last_index_first_cell
                      : styles.tableCell
                  }
                >
                  {item.counter_group_name}
                </Text>
                <Text
                  style={
                    index === counter_group_wise_items?.length - 1
                      ? styles.table_last_index_name_last_cell
                      : styles.tableCellName
                  }
                >
                  {item.total_enrolments}
                </Text>
                <Text
                  style={
                    index === counter_group_wise_items?.length - 1
                      ? styles.table_last_index_last_cell
                      : styles.tableCell
                  }
                >
                  {item.total_devotees}
                </Text>

                <Text
                  style={
                    index === counter_group_wise_items?.length - 1
                      ? styles.table_last_index_last_cell
                      : styles.tableCell
                  }
                >
                  {item.cash_enrolments}
                </Text>

                <Text
                  style={
                    index === counter_group_wise_items?.length - 1
                      ? styles.table_last_index_last_cell
                      : styles.tableCell
                  }
                >
                  {item.cash_amount}
                </Text>
                <Text
                  style={
                    index === counter_group_wise_items?.length - 1
                      ? styles.table_last_index_last_cell
                      : styles.tableCell
                  }
                >
                  {item.dd_enrolments}
                </Text>
                <Text
                  style={
                    index === counter_group_wise_items?.length - 1
                      ? styles.table_last_index_last_cell
                      : styles.tableCell
                  }
                >
                  {item.dd_amount}
                </Text>

                <Text
                  style={
                    index === counter_group_wise_items?.length - 1
                      ? styles.table_last_index_last_cell
                      : styles.tableCell
                  }
                >
                  {item.card_enrolments}
                </Text>

                <Text
                  style={
                    index === counter_group_wise_items?.length - 1
                      ? styles.table_last_index_last_cell
                      : styles.tableCell
                  }
                >
                  {item.card_amount}
                </Text>

                <Text
                  style={
                    index === counter_group_wise_items?.length - 1
                      ? styles.table_last_index_last_cell
                      : styles.tableCell
                  }
                >
                  {item.upi_amount}
                </Text>
                <Text
                  style={
                    index === counter_group_wise_items?.length - 1
                      ? styles.table_last_index_last_cell
                      : styles.table_last_index_cell
                  }
                >
                  {item.upi_enrolments}
                </Text>
                <Text
                  style={
                    index === counter_group_wise_items?.length - 1
                      ? styles.table_last_index_last_cell
                      : styles.tableCell
                  }
                >
                  {item.online_amount}
                </Text>
                <Text
                  style={
                    index === counter_group_wise_items?.length - 1
                      ? styles.table_last_index_last_cell
                      : styles.table_last_index_cell
                  }
                >
                  {item.online_enrolments}
                </Text>

                <Text
                  style={
                    index === counter_group_wise_items?.length - 1
                      ? styles.table_last_index_last_cell
                      : styles.table_last_index_cell
                  }
                >
                  {item?.sum}
                </Text>
              </View>
            ))}

            {/* Total row */}
            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader_first_header}>TOTAL</Text>
              <Text style={styles.tableColHeader}>
                {calculateColumnTotal("total_enrolments")}
              </Text>
              <Text style={styles.tableColHeader}>
                {calculateColumnTotal("total_devotees")}
              </Text>
              <Text style={styles.tableColHeader}>
                {calculateColumnTotal("cash_enrolments")}
              </Text>
              <Text style={styles.tableColHeader}>
                {calculateColumnTotal("cash_amount")}
              </Text>
              <Text style={styles.tableColHeader}>
                {calculateColumnTotal("dd_enrolments")}
              </Text>
              <Text style={styles.tableColHeader}>
                {calculateColumnTotal("dd_amount")}
              </Text>
              <Text style={styles.tableColHeader}>
                {calculateColumnTotal("card_enrolments")}
              </Text>
              <Text style={styles.tableColHeader}>
                {calculateColumnTotal("card_amount")}
              </Text>
              <Text style={styles.tableColHeader}>
                {calculateColumnTotal("upi_enrolments")}
              </Text>
              <Text style={styles.tableColHeader}>
                {calculateColumnTotal("upi_amount")}
              </Text>
              <Text style={styles.tableColHeader}>
                {calculateColumnTotal("sum")}
              </Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default CounterGroupWisePDF;
