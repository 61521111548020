import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import BookingPortal from "./booking_portal";
import IrumudiPortal from "./irumudi_portal";
import VipBookingPortal from "../vip_booking_portal/vip_booking_portal";
import { Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  get_active_season_query,
  useDynamicSelector,
} from "../../../../services/redux";
import moment from "moment";
import VipBookingSecondVipPortal from "../vip_booking_portal/vip_booking_second_login_portal ";
import DistrictBookingPortalForm from "./booking_portal_form/district_booking_portal_form";
import DistrictBookingPortal from "./booking_portal_form/district_booking_portal";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../routes/my_routes";

const BookingAndIrumudiPortal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selected_option, set_selected_option] = useState("");
  const { start_date: seasonStart, end_date: seasonEnd } =
    useDynamicSelector("get_active_season");

  useEffect(() => {
    fetchActiveSeason();
  }, []);

  const fetchActiveSeason = () => {
    const key = [{ key: "get_active_season", loading: false }];
    const query = get_active_season_query;
    dispatch(dynamicRequest(key, query, {}));
  };

  const handleOptionChange = (option) => {
    set_selected_option(option);
    dispatch(dynamicClear("get_booking_receipt"));
  };

  const handle_back_click = () => {
    set_selected_option("");
  };

  // Define colors for each option
  const optionColors = {
    "District Irumudi Booking": "#ff5900",
    "Admin Portal": "#EA6A47",
    "Irumudi Receipt": "#AC3E31",
    "VIP Irumudi Booking": "#D32d41",
  };

  const handle_traveler = () => {
    history.push(ROUTES.TRAVELERS);
  };

  const handle_fasting_rituals = () => {
    history.push(ROUTES.FASTING_RITUALS);
  };

  const handle_sakthi_maalai = () => {
    history.push(ROUTES.SAKTHI_MAALAI);
  };
  const handle_original_mantra = () => {
    history.push(ROUTES.ORIGINAL_MANTRA);
  };

  return (
    <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Text fontSize="15px">Welcome To Irumudi Booking System</Text>
        <Text color="#bd0000" fontSize="12px" marginTop="10px">
          {seasonStart && seasonEnd
            ? `Date: ${moment(seasonStart).format("DD-MMM-YYYY")} To ${moment(
                seasonEnd
              ).format("DD-MMM-YYYY")}`
            : null}
        </Text>
      </Box>
      <HStack space={"15px"} justifyContent={"center"}>
        <VStack space={"15px"} justifyContent={"center"}>
          <Typography
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "white",
              fontSize: "14px",
            }}
            onClick={handle_original_mantra}
          >
            மூல மந்திரம்
          </Typography>
          <Typography
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "white",
              fontSize: "14px",
            }}
            onClick={handle_sakthi_maalai}
          >
            சக்தி மாலை அணியும் முறை
          </Typography>
        </VStack>

        {!selected_option && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Row
              justify="center"
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              {[
                "District Irumudi Booking",
                "VIP Irumudi Booking",
                "Admin Portal",
                "Irumudi Receipt",
              ].map((option) => (
                <Col
                  key={option}
                  className="login-menu"
                  onClick={() => handleOptionChange(option)}
                  style={{
                    backgroundColor: optionColors[option],
                    cursor: "pointer",
                  }}
                >
                  <div>{option}</div>
                </Col>
              ))}
            </Row>
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "15px",
            flexDirection: "column",
          }}
        >
          {selected_option === "Admin Portal" && (
            <BookingPortal handle_back_click={handle_back_click} />
          )}
          {selected_option === "Irumudi Receipt" && (
            <IrumudiPortal handle_back_click={handle_back_click} />
          )}
          {selected_option === "VIP Irumudi Booking" && (
            <VipBookingPortal handle_back_click={handle_back_click} />
          )}
          {selected_option === "District Irumudi Booking" && (
            <DistrictBookingPortal handle_back_click={handle_back_click} />
          )}
        </div>
        <VStack space={"15px"} justifyContent={"center"}>
          <Typography
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "white",
              fontSize: "14px",
            }}
            onClick={handle_traveler}
          >
            பயணம் வருவோர்க்கு
          </Typography>
          <Typography
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "white",
              fontSize: "14px",
            }}
            onClick={handle_fasting_rituals}
          >
            விரத வழிபாட்டு முறைகள்
          </Typography>
        </VStack>
      </HStack>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
        }}
        mx={{ xs: "10px", sm: "10px", lg: "0px" }}
        textAlign={"center"}
      >
        <Text fontSize="15px">
          சக்தி மாலை அணிந்து இருமுடி அபிஷேகம் செய்பவர்கள் மட்டும் பதிவு
          செய்யவும்
        </Text>
        <Text fontSize="15px">
          Only those who wear Shakti Maalai and perform Irumudi Abhishekam
          should register{" "}
        </Text>
      </Box>
    </Box>
  );
};

export default BookingAndIrumudiPortal;
