import { gql } from "@apollo/client";
import { Tag } from "antd";
import { startCase } from "lodash";
import * as XLSX from "xlsx";

// export const API_URL = "https://masmapi.shakthiapps.com/graphql";
// export const API_URL = "http://192.168.1.53:5009/graphql";

export const PORT = 7878;

export const languages = ["en"];
export const ProjectName = "MASM";
export const defaultPageLimit = 999999999999;
export const defaultPageNumber = 1;
export const page_count = 12;
export const operator_matches_page_count = 1;
export const DEFAULT_COUNTRY_CODE = "IN";
export const DEFAULT_STATE_CODE = "TN";
export const IS_TABLE_SCROLLABLE = true;

export const astrology_charts = [
  { path: "D1", value: "Brith Chart" },
  { path: "D9", value: "Navamansha Chart" },
];

export const GOOGLE_API_KEY = "AIzaSyCVY8hRJ-X4VVYVuDECrRQynxwvuk6Kgow";
export const LOCATION_PROVIDER = "google";

export const ASTROLOGY_USER_ID = "620110";
export const ASTROLOGY_API_KEY = "cb343e1cba3ecc769520b97ea12cfdc9";
export const ASTROLOGY_URL =
  "https://json.astrologyapi.com/v1/horo_chart_image/";

export const ASTROLOGY_BASE_URL =
  "https://json.astrologyapi.com/v1/horo_chart_image/";

export const IMAGE_URL = "https://cdn.standardmatrimony.com/";

export const PROMO_URL = "https://stdmry.page.link/";

export const HOROSCOPE_URL = "https://horoscope.standardmatrimony.com/";

export const Data = [
  {
    name: "mano",
    gender: "m",
    dob: "1995-10-02T00:00:00.000Z",
    country_id: "IN",
    state_id: "TN",
    city_id: "chennai",
  },
];

export const fileQuery = gql`
  query get_signed_url($type: String!, $file_name: String!) {
    get_signed_url(type: $type, file_name: $file_name) {
      status
      url
      error {
        message
      }
    }
  }
`;
export const generateExcelFile = ({ header, data, filename }) => {
  // Create a new workbook and worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(account_sheet_items);

  // Define column headers
  const headers = header;
  // [
  //   "Institution",
  //   "Financial Year",
  //   "Income",
  //   "Demand",
  //   "Collection",
  //   "Balance",
  // ];

  // Set column headers in the worksheet
  const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
  const numColumns = headers.length;
  headerRange.e.c = numColumns - 1; // Adjust the end column of the header range
  for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
    const headerCell = XLSX.utils.encode_cell({ r: 0, c: col });
    worksheet[headerCell] = { v: headers[col] }; // Create a new cell object
  }

  // Process the data rows
  for (let row = headerRange.s.r + 1; row <= headerRange.e.r; row++) {
    const rowData = account_sheet_items[row - 1]; // Adjust index since row 0 is for headers
    const institutionName = rowData.institution.name;
    const termName = rowData.term.name;
    const income = rowData.demand.total_income;
    const demand = rowData.demand.contribution_net_accessed_amount;

    // Set values in respective columns
    const institutionCell = XLSX.utils.encode_cell({ r: row, c: 0 });
    worksheet[institutionCell] = { v: institutionName }; // Create a new cell object

    const termCell = XLSX.utils.encode_cell({ r: row, c: 1 });
    worksheet[termCell] = { v: termName }; // Create a new cell object

    const incomeCell = XLSX.utils.encode_cell({ r: row, c: 2 });
    worksheet[incomeCell] = { v: income }; // Create a new cell object

    const demandCell = XLSX.utils.encode_cell({ r: row, c: 3 });
    worksheet[demandCell] = { v: demand }; // Create a new cell object

    // Calculate values for Collection and Balance columns
    const collection = rowData.demand.contribution_net_accessed_amount;
    const balance = calculateBalance(income, collection);

    const collectionCell = XLSX.utils.encode_cell({ r: row, c: 4 });
    worksheet[collectionCell] = { v: collection }; // Create a new cell object

    const balanceCell = XLSX.utils.encode_cell({ r: row, c: 5 });
    worksheet[balanceCell] = { v: balance }; // Create a new cell object
  }

  // Update the worksheet range
  worksheet["!ref"] = XLSX.utils.encode_range(headerRange.s, headerRange.e);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate the Excel file
  const excelFile = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "binary",
  });

  // Download the Excel file
  const fileName = "data.xlsx";
  const blob = new Blob([s2ab(excelFile)], {
    type: "application/octet-stream",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
};
export const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

export const SpecialCharacterRemove = (input) => {
  // Remove forward slashes and concatenate the remaining string
  const result = input?.replace(/\//g, "");
  return result;
};

export const countFormat = (value) => {
  if (typeof value === "number") return `${value?.toLocaleString("en-IN")}`;
  else return "0";
};
export const PageBackgroundColor = {
  // back_color: "#f4f4f5",
  back_color: "#e6e6f5",
};

export const mandram_status = {
  false: "not_activated",
  true: "activated",
  true: "paid",
};
export const get_mandram_status = {
  not_activated: false,
  activated: true,
  paid: true,
};
export const formatStatus = (value) => {
  switch (value) {
    case "":
      return <Tag className="status-tag status-demand">{startCase(value)}</Tag>;
    case "activated":
    case "confirmed":
    case "paid":
      return <Tag className="status-tag status-active">{startCase(value)}</Tag>;
    case "not_activated":
      return <Tag className="status-tag status-closed">{startCase(value)}</Tag>;
    case "initiated":
      return (
        <Tag className="status-tag status-pending">{startCase(value)}</Tag>
      );
    case "Reverted":
      return <Tag className="status-tag status-revert">{startCase(value)}</Tag>;
    case "placed_not_paid":
      return (
        <Tag className="status-tag status-maturity">{startCase(value)}</Tag>
      );
    default:
      return value ? startCase(value) : "-";
  }
};
export const count_format = (amount = 0) => {
  let amountStr = amount.toFixed(2);
  let [integerPart, fractionalPart] = amountStr.split(".");

  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);
  if (otherDigits) {
    lastThreeDigits = "," + lastThreeDigits;
  }
  otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  let formattedInt = otherDigits + lastThreeDigits;

  return formattedInt;
};

export const secretary_name = [
  {
    District: "DINDIGUL",
    name: "R.SUBRAMANIAN",
    mobile: "8608077600",
  },
  {
    District: "CHENNAI SOUTH",
    name: "SAKTHI N.UMA NATARAJAN",
    mobile: "8610702389",
  },
  {
    District: "ARIYALUR",
    name: "SAKTHI S.K.RAMACHANDRAN",
    mobile: "9047414485",
  },
  {
    District: "THANE",
    name: "S.S.MANI",
    mobile: "9220559547",
  },
  {
    District: "MUMBAI",
    name: "S.MANIMEGALAI",
    mobile: "9322338240",
  },
  {
    District: "CUDDALORE",
    name: "K.KIRUPANANDHAN",
    mobile: "9345273408",
  },
  {
    District: "CHITTOOR RURAL",
    name: "T.GANGADHARAM",
    mobile: "9397050827",
  },
  {
    District: "PUNE - MAHARASHTRA",
    name: "VAIDEGHI RAJARAMAN",
    mobile: "9422526452",
  },
  {
    District: "HYDERABAD",
    name: "C.KALADHAR",
    mobile: "9440422338",
  },
  {
    District: "VIJAYAWADA",
    name: "B.SURESHKUMAR",
    mobile: "9440528015",
  },
  {
    District: "CHITTOOR URBAN",
    name: "K.SARITHA SANKARBABU",
    mobile: "9441600300",
  },
  {
    District: "VIRUDHUNAGAR",
    name: "R.PADMANABHAN",
    mobile: "9442058894",
  },
  {
    District: "THANJAVUR",
    name: "B.S.VASAN",
    mobile: "9443156395",
  },
  {
    District: "SIVAGANGAI",
    name: "SAKTHI R.KANAGASABAI",
    mobile: "9443177634",
  },
  {
    District: "KARUR",
    name: "N.JEYACHANDIRAN",
    mobile: "9443185714",
  },
  {
    District: "IDUKKI",
    name: "M.SATHISHKUMAR",
    mobile: "9443386566",
  },
  {
    District: "SALEM",
    name: "S.CHANDHIRA MOHAN",
    mobile: "9443392212",
  },
  {
    District: "KANYAKUMARI",
    name: "SAKTHI R.SELVAKUMAR",
    mobile: "9443694125",
  },
  {
    District: "PUDUKOTTAI",
    name: "K.KALIYAPERUMAL",
    mobile: "94438371747",
  },
  {
    District: "COIMBATORE",
    name: "V.KRISHNAMOORTHY",
    mobile: "9443937780",
  },
  {
    District: "PERAMBALUR",
    name: "K.KARUPPAIYA",
    mobile: "9443952957",
  },
  {
    District: "KANCHIPURAM",
    name: "SAKTHI V.VELU",
    mobile: "9444964419",
  },
  {
    District: "KARNATAKA STATE",
    name: "S.RAJAGOPAL",
    mobile: "9448682626",
  },
  {
    District: "ANDAMAN",
    name: "R.GURUSAMY",
    mobile: "9474207828",
  },
  {
    District: "CHENNAI CENTRAL",
    name: "S.RAJA SRI GOUTHAM",
    mobile: "9481010197",
  },
  {
    District: "VILLUPURAM",
    name: "SAKTHI K.M.MURTHY",
    mobile: "9486169930",
  },
  {
    District: "RAMANATHAPURAM",
    name: "SAKTHI P.RAJU",
    mobile: "9486561888",
  },
  {
    District: "ERODE",
    name: "SAKTHI A.NATARAJAN",
    mobile: "9486840705",
  },
  {
    District: "KRISHNAGIRI SOUTH",
    name: "R.JEYACHANDIRAN",
    mobile: "9500388110",
  },
  {
    District: "THIRUVANNAMALAI",
    name: "P.AMARESAN",
    mobile: "9597991771",
  },
  {
    District: "VISAKAPATTINAM",
    name: "R.RAJ",
    mobile: "9652557133",
  },
  {
    District: "VELLORE WEST",
    name: "A.SUBRAMANI",
    mobile: "9677687257",
  },
  {
    District: "THIRUNELVELI",
    name: "SAKTHI S.NATARAJAN",
    mobile: "9750930575",
  },
  {
    District: "THIRUVALLUR",
    name: "K.MOHANA SUNDARI",
    mobile: "9790973831",
  },
  {
    District: "TRICHY",
    name: "SAKTHI V.KATHIRAVAN",
    mobile: "9842032495",
  },
  {
    District: "MADURAI",
    name: "P.ASOKAN",
    mobile: "9842198055",
  },
  {
    District: "TUTICORIN",
    name: "R.MURUGAN",
    mobile: "9843593407",
  },
  {
    District: "PUDHUCHERRY",
    name: "A.MURUGANANDHAM",
    mobile: "9843795226",
  },
  {
    District: "CHENNAI NORTH",
    name: "SAKTHI M.VEDHAVALLI",
    mobile: "9884113777",
  },
  {
    District: "NILAGIRI",
    name: "SAKTHI INDHIRANI NATARAJAN",
    mobile: "9894791210",
  },
  {
    District: "NAMAKKAL",
    name: "S.GANESAN",
    mobile: "9943720389",
  },
  {
    District: "THIRUPPUR",
    name: "SARASWATHI SATHASIVAM",
    mobile: "9965584826",
  },
  {
    District: "DHARMAPURI",
    name: "D.K. PALANIAMMAL",
    mobile: "8122541128",
  },
  {
    District: "KRISHNAGIRI NORTH",
    name: "JEYAMMA",
    mobile: "9443057483",
  },
  {
    District: "PALAKKAD",
    name: "K. JEYABALAN",
    mobile: "9447879392",
  },
  {
    District: "VELLORE SOUTH",
    name: "D. MURUGESAN",
    mobile: "9443270049",
  },
  {
    District: "VELLORE NORTH",
    name: "C. MANI",
    mobile: "9751355675",
  },
  {
    District: "VELLORE EAST",
    name: "M. SATHYANARAYANAN",
    mobile: "9442416653",
  },
  {
    District: "THENI",
    name: "S. VENIRAJ",
    mobile: "9751343397",
  },
];
