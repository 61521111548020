import { gql } from "@apollo/client";
export const query_enroll_action = gql`
  query get_enroll_action($filter: enroll_action_filter_input) {
    get_enroll_action(filter: $filter) {
      id
      status
      count
      booking_status

      date_slot_id
      date_slot {
        date
        date_string
      }
      booking_reference
      mandram {
        id
        name
        code
        district {
          name
        }
      }
      booking_user {
        name
        mobile
        email
      }
      booking_status
      count
      booking_user {
        name
        mobile
      }
      enrolment_list {
        counter_id
        sequence_count {
          count
        }
      }
    }
  }
`;
