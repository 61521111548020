import { Card, Col, Row } from "antd";
import React, { useState } from "react";
import { useDynamicSelector } from "../../../../../services/redux";
import CounterPaymentReportModal from "../counter_payment_report_modal/counter_payment_report_modal";
import PrintHeader from "../../../common/print_header";

const CounterPaymentReportDetails = (props) => {
  const { count, selected_values, counter, total_amount, selected_date } =
    props;
  const [visible, set_visible] = useState(false);

  const { items: counter_wise_payment_list } = useDynamicSelector(
    "get_counter_wise_summary"
  );
  const { items: counter_list } = useDynamicSelector("get_all_counter_list");
  console.log("counter_list", counter_list);

  const counter_th_td_style = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "center",
    width: "25%",
  };

  const counter_th_style = {
    border: "transparent",
    height: "40px",
  };

  // const handle_print = () => {
  //   set_visible(true);
  // };

  return (
    <div>
      <div className="print-header" style={{ padding: "15px" }}>
        <PrintHeader title={"COUNTER USER WISE REPORT"} />
        <h4
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          COUNTER PAYMENT REPORT
        </h4>
        <h5
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* {report_date?.date} */}
        </h5>
      </div>
      <div style={{ marginTop: "20px" }}>
        {selected_values && (
          <Card
            className="counter-user-wise-report screen-table"
            style={{
              width: "100%",
              height: "100%",
              // margin: "auto",
              // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div
              style={
                {
                  // width: "96%",
                  // display: "flex",
                  // justifyContent: "center",
                }
              }
            >
              <Row
                style={{
                  // border: "1px solid black",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  backgroundColor: "#275670",
                  color: "white",
                }}
              >
                Omsakthi
              </Row>
              <Row
                style={{
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderBottom: "1px solid black",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                Counters:{" "}
                {counter
                  ? counter
                  : counter_list?.map((counter) => counter?.name).join(",")}
              </Row>
              <Row
                style={{
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderBottom: "1px solid black",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                Date:
                {selected_date?.length === 0
                  ? "All Date"
                  : selected_date?.join(",")}
                {/* Date:{selected_date ? selected_date : "All Date"} */}
                {/* Date:{selected_date} */}
              </Row>

              <Row
                style={{
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",

                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                <Col
                  span={12}
                  style={{
                    borderRight: "1px solid black",
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  No of irumudi: {count?.no_of_irumudi}
                </Col>
                <Col
                  span={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  Total Amount:
                  {`₹${(count?.total_amount).toLocaleString("en-IN")}`}
                </Col>
              </Row>

              <Row
                style={{
                  // border: "1px solid black",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  backgroundColor: "#275670",
                  color: "white",
                }}
              >
                Payment Details
              </Row>

              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  {/* <tr style={{ border: "1px solid black" }}>
                <th style={counter_th_style}>Counter:</th>
              </tr> */}
                  {/* <tr style={{ border: "1px solid black" }}>
                <th style={counter_th_style}>Date:</th>
              </tr> */}
                  {/* <tr>
                <th style={counter_th_td_style}>No of Irumudi:</th>
                <th style={counter_th_td_style}>
                  Total Amount:{count?.received_amount}
                </th>
              </tr> */}
                  {/* <tr style={{ border: "1px solid black" }}>
                <th style={counter_th_style}>Payment Details</th>
              </tr> */}
                </thead>

                <tbody>
                  <tr>
                    <td style={counter_th_td_style}>Card</td>
                    <td style={counter_th_td_style}>
                      {`₹${(count?.received_card_amount).toLocaleString(
                        "en-IN"
                      )}`}
                    </td>
                  </tr>
                  <tr>
                    <td style={counter_th_td_style}>Cash</td>
                    <td style={counter_th_td_style}>
                      {`₹${(count?.received_cash_amount).toLocaleString(
                        "en-IN"
                      )}`}
                    </td>
                  </tr>

                  <tr>
                    <td style={counter_th_td_style}>DD</td>
                    <td style={counter_th_td_style}>
                      {`₹${(count?.received_dd_amount).toLocaleString(
                        "en-IN"
                      )}`}
                    </td>
                  </tr>
                  <tr>
                    <td style={counter_th_td_style}>UPI</td>
                    <td style={counter_th_td_style}>
                      {`₹${(count?.received_upi_amount).toLocaleString(
                        "en-IN"
                      )}`}
                    </td>
                  </tr>
                  {/* <tr>
                  <td style={counter_th_td_style}>Prepaid</td>
                  <td style={counter_th_td_style}>
                    {getAmountFormatWithSymbol(count?.total_prepaid)}
                  </td>
                </tr> */}
                </tbody>
              </table>
              {/* <Space style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginBottom: "170px" }}>
              <CounterPaymentDetails />
            </div>
            <AmountDetailsCard />
          </Space> */}
            </div>
          </Card>
        )}
      </div>

      <div
        className="table table-bordered print-table"
        style={{
          // display: "flex",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Row
            style={{
              // border: "1px solid black",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              // backgroundColor: "#275670",
              // color: "white",
              borderTop: "1px solid black",
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              width: "92%",
            }}
          >
            Omsakthi
          </Row>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Row
            style={{
              borderTop: "1px solid black",
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              width: "92%",
            }}
          >
            Counters:
            {counter
              ? counter
              : counter_list?.map((counter) => counter?.name).join(",")}
          </Row>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Row
            style={{
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              width: "92%",
            }}
          >
            Date:
            {selected_date?.length === 0
              ? "All Date"
              : selected_date?.join(",")}
            {console.log("selected_date", selected_date)}
            {/* Date:{selected_date ? selected_date : "All Date"} */}
            {/* Date:{selected_date} */}
          </Row>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Row
            style={{
              borderLeft: "1px solid black",
              borderRight: "1px solid black",

              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              width: "92%",
            }}
          >
            <Col
              span={12}
              style={{
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
              }}
            >
              No of irumudi: {count?.no_of_irumudi}
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
              }}
            >
              Total Amount:
              {`₹${count?.total_amount?.toLocaleString("en-IN")}`}
            </Col>
          </Row>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Row
            style={{
              // border: "1px solid black",
              width: "92%",

              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              // backgroundColor: "#275670",
              // color: "white",
              borderTop: "1px solid black",
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
            }}
          >
            Payment Details
          </Row>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <table style={{ width: "92%", borderCollapse: "collapse" }}>
            <thead>
              {/* <tr style={{ border: "1px solid black" }}>
                <th style={counter_th_style}>Counter:</th>
              </tr> */}
              {/* <tr style={{ border: "1px solid black" }}>
                <th style={counter_th_style}>Date:</th>
              </tr> */}
              {/* <tr>
                <th style={counter_th_td_style}>No of Irumudi:</th>
                <th style={counter_th_td_style}>
                  Total Amount:{count?.received_amount}
                </th>
              </tr> */}
              {/* <tr style={{ border: "1px solid black" }}>
                <th style={counter_th_style}>Payment Details</th>
              </tr> */}
            </thead>

            <tbody>
              <tr>
                <td style={counter_th_td_style}>Card</td>
                <td style={counter_th_td_style}>
                  {`₹${count?.received_card_amount?.toLocaleString("en-IN")}`}
                </td>
              </tr>
              <tr>
                <td style={counter_th_td_style}>Cash</td>
                <td style={counter_th_td_style}>
                  {`₹${count?.received_cash_amount?.toLocaleString("en-IN")}`}
                </td>
              </tr>

              <tr>
                <td style={counter_th_td_style}>DD</td>
                <td style={counter_th_td_style}>
                  {`₹${count?.received_dd_amount?.toLocaleString("en-IN")}`}
                </td>
              </tr>
              <tr>
                <td style={counter_th_td_style}>UPI</td>
                <td style={counter_th_td_style}>
                  {`₹${count?.received_upi_amount?.toLocaleString("en-IN")}`}
                </td>
              </tr>
              <tr>
                <td style={counter_th_td_style}>UPI</td>
                <td style={counter_th_td_style}>
                  {`₹${count?.received_upi_amount?.toLocaleString("en-IN")}`}
                </td>
              </tr>
              {/* <tr>
                  <td style={counter_th_td_style}>Prepaid</td>
                  <td style={counter_th_td_style}>
                    {getAmountFormatWithSymbol(count?.total_prepaid)}
                  </td>
                </tr> */}
            </tbody>
          </table>
        </div>
        {/* <Space style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginBottom: "170px" }}>
              <CounterPaymentDetails />
            </div>
            <AmountDetailsCard />
          </Space> */}
      </div>

      <style>
        {`
    @media screen {
      .print-table {
        display: none;
      }
      .print-header {
        display: none;
      }
    }

    @media print {
      .screen-table {
        display: none !important;
      }
      .print-header {
        display: block !important;
      }

      @media print {
        @page {
          size: A4;
          margin: 0; 
        }
  
        body::before {
          content: counter(page);
          top: 0;
          right: 1cm;
        }
      }
    }
  `}
      </style>
      <CounterPaymentReportModal
        visible={visible}
        set_visible={set_visible}
        count={count}
        counter={counter}
        // selected_date={selected_date}
        total_amount={total_amount}
      />
    </div>
  );
};
export default CounterPaymentReportDetails;
