import { gql } from "@apollo/client";
export const query_group_wise_report = gql`
query get_district_group_wise_count($filter: district_group_wise_count_filter_input) {
  get_district_group_wise_count(filter: $filter) {
    items {
      district_group_name
      district_group_tamil_name
      selected_date_devotees
      till_date_devotees
      total_devotees
      previous_season_enrolled_count
    }
    error {
      status_code
      message
    }
  }
}
`;
