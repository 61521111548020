import { Table } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  get_sub_district_wise_count_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import CustomPageTitle from "../../common/custom_page_title";

const SubDistrictWiseTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { items } = useDynamicSelector(
    "get_district_admin_dashboard_subdistrict_count_summary"
  );

  console.log("itemsssss", items);

  useEffect(() => {
    get_sub_district_wise_count();
  }, []);

  const get_sub_district_wise_count = () => {
    let keys = [
      {
        key: "get_district_admin_dashboard_subdistrict_count_summary",
        loading: true,
      },
    ];
    let query = get_sub_district_wise_count_query;
    let variable = {};
    dispatch(dynamicRequest(keys, query, variable));
  };

  const columns = [
    {
      title: t("sub_district_name"),
      dataIndex: "sub_district_name",
      key: "sub_district_name",
      width: "100px",
      fixed: true,
    },
    {
      title: t("code"),
      dataIndex: "sub_district_code",
      key: "sub_district_code",
      width: "85px",
      fixed: "left",
    },
    {
      title: t("booked_counts"),
      dataIndex: "booked_count",
      key: "booked_count",
      width: "100px",
      fixed: true,
    },
    {
      title: t("enrolled"),
      dataIndex: "arrived_count",
      key: "arrived_count",
      fixed: true,
      width: "100px",
    },
    {
      title: t("not_arrived"),
      dataIndex: "yet_to_enroll_count",
      key: "yet_to_enroll_count",
      fixed: true,
      width: "130px",
    },
  ];
  return (
    <>
      <CustomPageTitle title={"Sub District Wise Summary"} />
      <Table
        columns={columns}
        dataSource={items}
        data-name-table="district-wise-report"
        pagination={false}
      />
    </>
  );
};

export default SubDistrictWiseTable;
