import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { retrieveItem, send_response_to_printer_service } from '../../../../helpers/functions';
import { dynamicClear, dynamicRequest, mutation_enrolment_ticket_print } from '../../../../services/redux';
import { toast } from 'react-toastify';
import { Button, Modal, Popconfirm, Typography } from 'antd';
import { IoMdClose } from 'react-icons/io';
import { get_enroll_booking_list_query } from '../../../../services/redux/slices/graphql/graphql_enroll_booking';

const SpotBookingAndEnrolmentPrint = (props) => {
    const {
        is_print_token_modal_visible,
        set_is_print_token_modal_visible,
        enrolled_id,
        spot_enrol_button_ref,
        set_receipt_modal_open,
        // set_front_url,
        // set_back_url,
      receipt_form
      } = props;
      const dispatch = useDispatch();
      const session_id = retrieveItem("session_id");
      const [is_loading, set_is_loading] = useState(false);
      const [token_printer_response, set_token_printer_response] = useState("");
    
    
      useEffect(() => {
        if (token_printer_response === "printing started") {
          TicketsPrinted();
          set_is_loading(false);
          set_receipt_modal_open(false)
          close_token_printer_modal();
          set_token_printer_response("");
          get_enroll_booking_list()
          dispatch(dynamicClear("create_spot_booking_and_enroll"));
          dispatch(dynamicClear("asynchronous_check_request"));
        //   set_front_url("");
        //   set_back_url("");
          dispatch(dynamicClear("enrol_detail"));
          dispatch(dynamicClear("booking_details"));
          if (spot_enrol_button_ref.current) {
            spot_enrol_button_ref.current.focus();
            receipt_form.resetFields()
          }
        } else if (
          token_printer_response === "tickets already printed" ||
          token_printer_response === "invalid enrolment status" ||
          token_printer_response === "error"
        ) {
          toast.error("Tickets already printed");
          close_token_printer_modal();
          set_token_printer_response("");
          if (spot_enrol_button_ref.current) {
            spot_enrol_button_ref.current.focus();
            receipt_form.resetFields()
          }
          dispatch(dynamicClear("create_spot_booking_and_enroll"));
          dispatch(dynamicClear("asynchronous_check_request"));
        }
      }, [token_printer_response, spot_enrol_button_ref]);
    
      const TicketsPrinted = async () => {
        let key = [{ key: "set_enrolment_ticket_printed", loading: true }];
        let query = mutation_enrolment_ticket_print;
        let variables = {
          id: enrolled_id,
        };
        dispatch(dynamicRequest(key, query, variables));
      };

      const get_enroll_booking_list = () => {
          let key = [{ key: "get_current_session_enrolment_list", loading: true }];
          let query = get_enroll_booking_list_query;
          let variables = null;
          dispatch(dynamicRequest(key, query, variables));
        };
    
      const close_token_printer_modal = () => {
        set_is_print_token_modal_visible(false);
      };

  return (
    <Modal
    title={"Print Token"}
    open={is_print_token_modal_visible}
    closeIcon={
      <span style={{ marginTop: "2px" }}>
        <Popconfirm
          title={"Are you sure to process without printing?"}
          onConfirm={close_token_printer_modal}
          okText="Yes"
          cancelText="No"
        >
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </Popconfirm>
      </span>
    }
    footer={[
      <Button
        loading={is_loading}
        style={{
          color: "white",
          border: "transparent",
          backgroundColor: "#168f92",
        }}
        htmlType="submit"
        onClick={() => {
          send_response_to_printer_service(
            enrolled_id,
            session_id,
            set_is_loading,
            TicketsPrinted,
            close_token_printer_modal,
            set_token_printer_response
          );
        }}
      >
        {"Print"}
      </Button>,
      ,
    ]}
  >
    <Typography>
      {
        "Your information saved successfully..! Do you want to print the receipt ?"
      }
    </Typography>
  </Modal>
  )
}

export default SpotBookingAndEnrolmentPrint
