import { gql } from "@apollo/client";

export const query_district_dashboard = gql`
  query {
    get_district_admin_dashboard_mandram_count_summary {
      items {
        mandram_id
        mandram_name
        mandram_code
        count {
          booked
          arrived
          excess
          yet_to_enroll
          cancelled
        }
      }
    }
  }
`;

export const get_sub_district_wise_count_query=gql`
query get_district_admin_dashboard_subdistrict_count_summary{
  get_district_admin_dashboard_subdistrict_count_summary{
    items{
      error{
        status_code
        message
      }
      sub_district_id
      sub_district_name
      sub_district_code
      booked_count
      arrived_count
      yet_to_enroll_count
    }
  }
}`
