import { Button, Col, Form, Input, Row, Segmented, Typography } from "antd";
import React, { useEffect, useState } from "react";
import BookingLoginButton from "./booking_login_button";
import {
  create_system_otp_mutation,
  dynamicClear,
  dynamicRequest,
  query_login,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { storeItem } from "@helpers/functions";
import { redirect_page } from "../../../../../helpers/functions";
import { navigate } from "../../../../../helpers/functions";
import { ROUTES } from "../../../../routes/my_routes";
import BookingUsernameFormItem from "./booking_username_form_item";
import BookingPasswordFormItem from "./booking_portal_password_form_item";
import BookingLoginFormItem from "../../booking_portal/booking_login_form_item";
import BookingPortalSendOtpButton from "../../booking_portal/booking_portal_send_otp_button";
import { useTranslation } from "react-i18next";
import TermsAndConditions from "./terms_and_conditions";

const IrumudiAndBookingPortalForm = ({ handle_back_click }) => {
  const [form] = Form.useForm();
  const [countdown, set_countdown] = useState(60);
  const [is_otp_status, set_is_otp_status] = useState(false);
  const [is_login_portal, set_is_login_portal] = useState(
    "login_with_password"
  );
  const [is_otp, set_is_otp] = useState(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    status: login_status,
    session_id,
    district,
    sub_district,
    error,
    system_user,
    page_accesses,
    page_component_accesses,
  } = useDynamicSelector("login");
  const booking_user_mobile = Form.useWatch("booking_user_mobile", form);

  const { status: create_otp_status, error: create_otp_error } =
    useDynamicSelector("create_system_otp");

  useEffect(() => {
    if (error) {
      if (error.message === "not found") {
        toast.error("Please enter a valid username");
        dispatch(dynamicClear("login"));
      } else if (error.message === "invalid_credentials") {
        toast.error("Please enter a valid password");
        dispatch(dynamicClear("login"));
      }
    }
    if (login_status === "success") {
      storeItem("session_id", session_id);
      storeItem("role", system_user?.roles?.[0]?.name);
      storeItem("page_accesses", page_accesses);
      storeItem("system_user", system_user?.name);
      storeItem("district_name", district?.name);
      storeItem("district_id", district?.id);
      storeItem("sub_district_id", sub_district?.id);
      storeItem("sub_district_", sub_district?.district?.id);
      storeItem("system_user_mail_id", system_user?.email);

      storeItem("page_component_accesses", page_component_accesses);

      if (system_user?.roles?.[0]?.name === "Enrolment Officer") {
        redirect_page(ROUTES.ENROLL);
      }
      if (system_user?.roles?.[0]?.name === "Super Admin") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "District Admin") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "District User") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "Bank Enrolment Officer") {
        redirect_page(ROUTES.ENROLL);
      }
      if (system_user?.roles?.[0]?.name === "Bank Branch Enrolment Officer") {
        redirect_page(ROUTES.ENROLL);
      }
      if (system_user?.roles?.[0]?.name === "Dashboard User") {
        redirect_page(ROUTES.DASHBOARD);
      }
      if (system_user?.roles?.[0]?.name === "Dashboard Admin") {
        redirect_page(ROUTES.DASHBOARD);
      }
      if (system_user?.roles?.[0]?.name === "Helpdesk Officer") {
        redirect_page(ROUTES.RECEIPT);
      }
      if (system_user?.roles?.[0]?.name === "Mandram Admin") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "MASM Admin") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "MASM User") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "Spl Enrolment Officer") {
        redirect_page(ROUTES.ENROLL);
      }
      if (system_user?.roles?.[0]?.name === "VIP Enrolment Officer") {
        redirect_page(ROUTES.VIP_BOOKING);
      }
      if (system_user?.roles?.[0]?.name === "Enrolment Action User") {
        redirect_page(ROUTES.ENROLL_ACTION);
      }
      if (system_user?.roles?.[0]?.name === "Mandram Renewal Officer") {
        redirect_page(ROUTES.MANDRAM_RENEWAL);
      }
      if (system_user?.roles?.[0]?.name === "Sub-district Admin") {
        redirect_page(ROUTES.IRUMUDI_CALENDAR);
      }
      if (system_user?.roles?.[0]?.name === "Sub-district User") {
        redirect_page(ROUTES.ADDITIONAL_USERS);
      }
      if (system_user?.roles?.[0]?.name === "Receipt User") {
        redirect_page(ROUTES.RECEIPT);
      }
      if (system_user?.roles?.[0]?.name === "Spot Booking Officer") {
        redirect_page(ROUTES.SPOT_BOOKING);
      }
    }
  }, [
    login_status,
    session_id,
    page_accesses,
    page_component_accesses,
    system_user,
    error,
  ]);

  const get_login = (values) => {
    console.log("values", values);

    let key = [{ key: "login", loading: true }];
    let query = query_login;

    const variables = {
      custom: {
        username: values?.username,
        password: values?.password,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_finish = (values) => {
    if (!navigator.onLine) {
      toast.error("Something went wrong please try again");
      return;
    }
    get_login(values);
  };

  return (
    <>
      <div>
        <Form onFinish={handle_finish} id="login_form" form={form}>
          <BookingUsernameFormItem />
          <BookingPasswordFormItem />
          <BookingLoginButton />
        </Form>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <Button
            style={{ width: "100%", height: "30px" }}
            onClick={handle_back_click}
          >
            Back
          </Button>
        </div>
        <TermsAndConditions />
      </div>
    </>
  );
};

export default IrumudiAndBookingPortalForm;
