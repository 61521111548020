import { Button, Modal } from "antd";
import { t } from "i18next";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  create_capture_request_mutation,
  dynamicClear,
  dynamicRequest,
  get_capture_request_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { FaRegEye } from "react-icons/fa";
let modal_Instance_request = Modal;

const CaptureVipUserIdProof = ({
  set_front_url,
  front_url,
  set_back_url,
  back_url,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const webcam_ref = useRef(null);

  const {
    id: create_capture_request_id,
    status: create_capture_request_status,
    error: create_capture_request_error,
  } = useDynamicSelector("create_capture_request");
  const {
    status: get_capture_status,
    front_attachment_url,
    back_attachment_url,
  } = useDynamicSelector("get_capture_request");

  useEffect(() => {
    if (create_capture_request_id) {
      modal_Instance_request.info({
        title: "ID capture process",
        content: (
          <div>
            <p>Processing user ID capture...</p>
            <p>Do not close or refresh the page.</p>
            <div className="loading">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        ),
        okButtonProps: {
          style: { display: "none" },
        },
        getContainer: document.body,
        className: "custom-modal",
        keyboard: false,
      });
      setTimeout(() => {
        get_capture_request();
      }, 10000);
    }
  }, [create_capture_request_id]);
  useEffect(() => {
    if (get_capture_status === "pending") {
      setTimeout(() => {
        get_capture_request();
      }, 5000);
    } else if (get_capture_status === "completed") {
      set_front_url(front_attachment_url);
      set_back_url(back_attachment_url);
      closeModal_instance_request();
      toast.success("ID captured successfully");
      dispatch(dynamicClear("create_capture_request"));
      dispatch(dynamicClear("get_capture_request"));
    } else if (get_capture_status === "failed") {
      closeModal_instance_request();
      toast.error("ID capture failed");
      dispatch(dynamicClear("create_capture_request"));
      dispatch(dynamicClear("get_capture_request"));
    }
  }, [get_capture_status]);

  const create_capture_request = () => {
    let key = [{ key: "create_capture_request", loading: true }];
    let query = create_capture_request_mutation;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_capture_request = () => {
    let key = [{ key: "get_capture_request", loading: true }];
    let query = get_capture_request_query;
    let variables = {
      id: create_capture_request_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_id_capture_click = () => {
    create_capture_request();
  };
  const closeModal_instance_request = () => {
    if (modal_Instance_request) {
      modal_Instance_request.destroyAll();
    }
  };

  return (
    <div style={{ display: "flex", gap: "20px" }}>
      <Button
        className="take-photo-button"
        onClick={handle_id_capture_click}
        ref={webcam_ref}
      >
        {front_url ? t("re_take") : t("capture_id_proof")}
      </Button>
      {front_url && (
        <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
          <FaRegEye style={{ color: "#1e90ff" }} />
          <a
            href={
              front_url instanceof Blob
                ? URL.createObjectURL(front_url)
                : front_url
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#1e90ff", textDecoration: "underline" }}
          >
            {"ID Front"}
          </a>
        </div>
      )}
      {back_url && (
        <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
          <FaRegEye style={{ color: "#1e90ff" }} />
          <a
            href={
              back_url instanceof Blob
                ? URL.createObjectURL(back_url)
                : back_url
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#1e90ff", textDecoration: "underline" }}
          >
            {"ID Back"}
          </a>
        </div>
      )}
    </div>
  );
};

export default CaptureVipUserIdProof;
