import { Table } from "antd";
import React from "react";
import { useDynamicSelector } from "../../../../services/redux";
import { getAmountFormatWithSymbol } from "../../../../helpers/functions";
import { StyleSheet } from "@react-pdf/renderer";
import PrintHeader from "../../common/print_header";
import { IS_TABLE_SCROLLABLE } from "../../../../helpers/constants";

const CounterUserWiseReportList = (props) => {
  const { total_amount, report_date } = props;
  const { items: counter_user_wise_items, loading: counter_user_wise_loading } =
    useDynamicSelector("get_counter_user_wise_summary");

  const styles = StyleSheet.create({
    tableCell: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  });

  const columns = [
    {
      title: "User",
      dataIndex: "user_name",
      key: "counter_name",
      width: "150px",
      fixed: "left",
    },
    {
      title: "All Enrolment",
      dataIndex: "total_enrolments",
      key: "total_enrolments",
      align: "right",
    },
    {
      title: "Devotees",
      dataIndex: "total_devotees",
      key: "total_devotees",
      align: "right",
    },
    {
      title: "Cash Amount",
      // dataIndex: "cash_amount",
      key: "cash_amount",
      align: "right",
      render: (record) => {
        return (
          <span>{getAmountFormatWithSymbol(record?.cash_amount) || 0}</span>
        );
      },
    },
    {
      title: "Cash Enrolments",
      dataIndex: "cash_enrolments",
      key: "cash_enrolments",
      align: "right",
    },
    {
      title: "Card Enrolments",
      dataIndex: "card_enrolments",
      key: "received_card_amount",
      align: "right",
    },
    {
      title: "Card Amount",
      // dataIndex: "card_amount",
      key: "card_amount",
      align: "right",
      render: (record) => {
        return (
          <span>{getAmountFormatWithSymbol(record?.card_amount) || 0}</span>
        );
      },
    },
    {
      title: "UPI Enrolments",
      dataIndex: "upi_enrolments",
      key: "upi_enrolments",
      align: "right",
    },
    {
      title: "UPI Amount",
      // dataIndex: "upi_amount",
      key: "title",
      align: "right",
      render: (record) => {
        return (
          <span>{getAmountFormatWithSymbol(record?.upi_amount) || 0}</span>
        );
      },
    },
    {
      title: "ONLINE Enrolments",
      dataIndex: "online_enrolments",
      key: "online_enrolments",
      align: "right",
    },
    {
      title: "ONLINE Amount",
      // dataIndex: "upi_amount",
      key: "title",
      align: "right",
      render: (record) => {
        return (
          <span>{getAmountFormatWithSymbol(record?.online_amount) || 0}</span>
        );
      },
    },
    {
      title: "Total Amount",
      dataIndex: "",
      key: "sum",
      fixed: "right",
      align: "right",
      width: "150px",
      render: (record) => {
        return (
          <span style={{ alignItems: "flex-end" }}>
            {getAmountFormatWithSymbol(record?.sum) || 0}
          </span>
        );
      },
    },
  ];

  const grand_total_enrolment = counter_user_wise_items
    ? counter_user_wise_items.reduce(
        (sum, record) => sum + (record.total_enrolments || 0),
        0
      )
    : 0;

  const grand_total_devotees = counter_user_wise_items
    ? counter_user_wise_items.reduce(
        (sum, record) => sum + (record.total_devotees || 0),
        0
      )
    : 0;

  const grand_total_cash_amount = counter_user_wise_items
    ? counter_user_wise_items.reduce(
        (sum, record) => sum + (record.cash_amount || 0),
        0
      )
    : 0;

  const grand_total_cash_enrolments = counter_user_wise_items
    ? counter_user_wise_items.reduce(
        (sum, record) => sum + (record.cash_enrolments || 0),
        0
      )
    : 0;

  const grand_total_card_enrolments = counter_user_wise_items
    ? counter_user_wise_items.reduce(
        (sum, record) => sum + (record.card_enrolments || 0),
        0
      )
    : 0;

  const grand_total_card_amount = counter_user_wise_items
    ? counter_user_wise_items.reduce(
        (sum, record) => sum + (record.card_amount || 0),
        0
      )
    : 0;

  const grand_total_upi_amount = counter_user_wise_items
    ? counter_user_wise_items.reduce(
        (sum, record) => sum + (record.upi_amount || 0),
        0
      )
    : 0;
  const grand_total_upi_enrolments = counter_user_wise_items
    ? counter_user_wise_items.reduce(
        (sum, record) => sum + (record.upi_enrolments || 0),
        0
      )
    : 0;
    const grand_total_online_amount = counter_user_wise_items
    ? counter_user_wise_items.reduce(
        (sum, record) => sum + (record.online_amount || 0),
        0
      )
    : 0;
  const grand_total_online_enrolments = counter_user_wise_items
    ? counter_user_wise_items.reduce(
        (sum, record) => sum + (record.online_enrolments || 0),
        0
      )
    : 0;
  const grand_total = counter_user_wise_items
    ? counter_user_wise_items.reduce(
        (sum, record) => sum + (record.sum || 0),
        0
      )
    : 0;

  return (
    <>
      <div className="print-header" style={{ padding: "15px" }}>
        <PrintHeader title={"COUNTER USER WISE REPORT"} />
        <h4
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          COUNTER USER WISE REPORT
        </h4>
        <h5
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {report_date?.date}
        </h5>
      </div>
      <Table
        className="counter-user-wise-report screen-table"
        style={{ margin: "25px", marginTop: "40px" }}
        data-name-table="district-wise-report"
        columns={columns}
        dataSource={counter_user_wise_items}
        loading={counter_user_wise_loading}
        pagination={false}
        scroll={{
          x: 1900,
          y: IS_TABLE_SCROLLABLE ? null : 300,
        }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <text style={{ fontWeight: "bold" }}>TOTAL</text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={1}>
                <text style={styles.tableCell}>
                  <b>{grand_total_enrolment}</b>
                </text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={2}>
                <text style={styles.tableCell}>
                  <b>{grand_total_devotees}</b>
                </text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={3}>
                <text style={styles.tableCell}>
                  <b> {getAmountFormatWithSymbol(grand_total_cash_amount)}</b>
                </text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={4}>
                <text style={styles.tableCell}>
                  <b>{grand_total_cash_enrolments}</b>
                </text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={5}>
                <text style={styles.tableCell}>
                  <b>{grand_total_card_enrolments}</b>
                </text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={6}>
                <b>
                  <text style={styles.tableCell}>
                    {getAmountFormatWithSymbol(grand_total_card_amount)}
                  </text>
                </b>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={7}>
                <text style={styles.tableCell}>
                  <b> {grand_total_upi_enrolments}</b>
                </text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={8}>
                <text style={styles.tableCell}>
                  <b> {getAmountFormatWithSymbol(grand_total_upi_amount)}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <text style={styles.tableCell}>
                  <b> {grand_total_online_enrolments}</b>
                </text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={8}>
                <text style={styles.tableCell}>
                  <b> {getAmountFormatWithSymbol(grand_total_online_amount)}</b>
                </text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={9}>
                <text style={styles.tableCell}>
                  <b> {getAmountFormatWithSymbol(grand_total)}</b>
                </text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      ></Table>

      <div style={{ padding: "10px" }}>
        <table
          className="table table-bordered print-table"
          style={{ padding: "10px", width: "100%", marginTop: "10px" }}
        >
          <thead style={{ width: "100%" }}>
            <tr>
              {columns.map((column) => (
                <th
                  className="header-style"
                  style={{ width: "100px" }}
                  key={column.key}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {counter_user_wise_items?.map((report, index) => (
              <tr key={index}>
                <td className="body-style text-left-align">
                  {report?.user_name}
                </td>
                <td className="body-style " style={{ textAlign: "right" }}>
                  {report?.total_enrolments}
                </td>

                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.total_devotees}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.cash_amount)}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.cash_enrolments}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.card_enrolments}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.card_amount)}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.upi_enrolments}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.upi_amount)}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.online_enrolments}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.online_amount)}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.sum)}
                </td>
              </tr>
            ))}
            <tr>
              <td className="body-style text-left-align">
                <b>Total</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b> {grand_total_enrolment}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_devotees}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total_cash_amount)}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_cash_enrolments}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_card_enrolments}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total_card_amount)}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_upi_enrolments}</b>
              </td>

              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total_upi_amount)}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_online_enrolments}</b>
              </td>

              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total_online_amount)}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total)}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <style>
        {`
    @media screen {
      .print-table {
        display: none;
      }
      .print-header {
        display: none;
      }
    }

    @media print {
      .screen-table {
        display: none !important;
      }
      .print-header {
        display: block !important;
      }

      @media print {
        @page {
          size: A4;
          margin: 1cm;
        }
  
        body::before {
          content: counter(page);
          top: 0;
          right: 1cm;
        }
      }
    }
  `}
      </style>
    </>
  );
};

export default CounterUserWiseReportList;
