import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDynamicSelector } from '../../../../services/redux';
import _orderBy from "lodash/orderBy";
import { Form, Select } from 'antd';


const CorpusReportToDate = (props) => {
    const { set_to_date ,set_selected_to_date} = props;
    const { t } = useTranslation();
  
    const { items } = useDynamicSelector("corpus_report_date");
    const ordered_date_slots = _orderBy(items, ["date"], ["asc"]);
  
    const on_change = (value, option) => {
      const selected_to_date = {
        id: value,
        selected_to_date: option?.children,
      };
      set_to_date(selected_to_date?.id);
      set_selected_to_date(option?.key)
    };
  return (
    <Form.Item
    name="to_date"
    label={t("to_date")}
    rules={[
      {
        required: true,
        message: "Please select to date",
      },
    ]}
  >
    <Select placeholder="Select To Date" allowClear onChange={on_change}>
      {ordered_date_slots?.map((date_option, index) => (
        <Select.Option key={date_option?.date_string} value={date_option?.id}>
          {date_option?.date_string}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
  )
}

export default CorpusReportToDate