import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDynamicSelector } from '../../../../services/redux';
import _orderBy from "lodash/orderBy";


const CorpusReportFromDate = (props) => {
    const { set_from_date,set_selected_from_date } = props;
  const { t } = useTranslation();

  const { items } = useDynamicSelector("corpus_report_date");
  const ordered_date_slots = _orderBy(items, ["date"], ["asc"]);

  const on_change = (value, option) => {
    console.log("datessssssss",value,option)
    const selected_from_date = {
      id: value,
      selected_date: option?.children,
    };
    set_from_date(selected_from_date?.id);
    set_selected_from_date(option?.key)
  };
  return (
    <Form.Item
      name="from_date"
      label={t("from_date")}
      rules={[
        {
          required: true,
          message: "Please select from date",
        },
      ]}
    >
      <Select placeholder="Select From Date" onChange={on_change} allowClear>
        {ordered_date_slots?.map((date_option, index) => (
          <Select.Option key={date_option?.date_string} value={date_option?.id}>
            {date_option?.date_string}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default CorpusReportFromDate