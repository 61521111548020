import { Button, Space } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { dynamicSet } from "../../../services/redux";

const UpdateDistrictUserAccessButtons = (props) => {
  const {
    handle_update,
    set_managing_provision_modal_visible,
    set_mandram_list,
    mandram_list,
    form,
  } = props;
  const dispatch = useDispatch();
  const handle_close = () => {
    set_managing_provision_modal_visible(false);
  };

  const select_all_checkbox = () => {
    const updated_checkboxes = mandram_list?.map((checkbox) => ({
      ...checkbox,
      is_active: true,
    }));
    dispatch(
      dynamicSet("district_user_initial_data", {
        initial_values: mandram_list.map((list) => list?.id),
      })
    );
    set_mandram_list(updated_checkboxes);
  };

  const deselect_all_checkbox = () => {
    console.log("ffdwfd");
    dispatch(dynamicSet("district_user_initial_data", { initial_values: [] }));
    const updated_checkboxes = mandram_list?.map((checkbox) => ({
      ...checkbox,
      is_active: false,
    }));
    set_mandram_list(updated_checkboxes);
    form.resetFields();
  };

  return (
    <>
      <Space>
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button style={{ height: "30px" }} onClick={select_all_checkbox}>
            Select All
          </Button>
          <Button style={{ height: "30px" }} onClick={deselect_all_checkbox}>
            Deselect All
          </Button>
        </Space>
        <Space
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button
            style={{
              color: "white",
              borderRadius: "4px",
              borderColor: "transparent",
              backgroundColor: "#f96451",
              width: "80px",
            }}
            onClick={handle_close}
          >
            Close
          </Button>
          <Button
            style={{
              backgroundColor: "#168f92",
              color: "white",
              borderRadius: "4px",
              borderColor: "transparent",
            }}
            onClick={handle_update}
          >
            Update
          </Button>
          {/* <Button
          style={{
            backgroundColor: "#168f92",
            color: "white",
            borderRadius: "8px",
            borderColor: "transparent",
          }}
          onClick={() => set_selected_button("all_mandram_selected")}
        >
          Select All
        </Button>
        <Button
          style={{
            backgroundColor: "#168f92",
            color: "white",
            borderRadius: "8px",
            borderColor: "transparent",
          }}
          onClick={() => {
            set_selected_button("all_mandram_deselected");
          }}
        >
          Deselect All
        </Button> */}
        </Space>
      </Space>
    </>
  );
};

export default UpdateDistrictUserAccessButtons;
