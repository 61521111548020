import React from "react";
import { Typography } from "antd";
import { Box } from "native-base";

const SakthiMaalai = () => {
  const { Text } = Typography;
  return (
    <div style={{ alignItems: "center", padding: "20px", overflowY: "auto" }}>
      <Box alignItems={"center"}>
        <Text
          style={{
            color: "black",
            textAlign: "justify",
            fontWeight: "bold",
            fontSize: "30px",
          }}
        >
          ஓம் சக்தி
        </Text>
      </Box>
      <Box alignItems={"center"}>
        <Text
          style={{
            color: "black",
            textAlign: "justify",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          சக்தி விரதமும் - சக்தி மாலை அணியும் முறையும்
        </Text>
      </Box>
      <div style={{ marginTop: "10px" }}>
        <Text
          style={{
            color: "black",
            textAlign: "justify",
            fontSize: "14px",
          }}
        >
          அருள்மிகு அன்னை ஆதிபராசக்திதன் அருள்வாக்கில், "அன்னையை மனமுருக
          நினைத்து, தன் குறைகளுக்காக வேண்டி, சக்தி மாலை அணிந்து முறைப்படி
          விரதமிருந்து, மேல்மருவத்தூர் சித்தர் பீடத்தினை வலம் வந்து அன்னையிடம்
          சரணடைந்தால், நினைத்த பலன் அத்தனையும் நிறைவேறும் என்று அருளி உள்ளாள்.
        </Text>

        <div style={{ marginTop: "10px" }}>
          <Text
            style={{
              color: "black",
              textAlign: "justify",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            நன்மைகள்:
          </Text>
        </div>
        <ul>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                உடல்நலமின்றி வாடுபவர்கள் நோய் நீங்கி நலம் பெறுவார்கள்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                அன்னை ஊழ்வினையை வேரறுத்து மன உறுதியையும் அமைதியையும் தருவாள்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                திருமணம் ஆகாதவர்களுக்கும் குறை நீங்கி விரைவில் திருமணம்
                நடைபெறும்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                மகப்பேறின்றி வேதனைப்படும் குடும்பத்தில் குழந்தைச் செல்வம்
                பெருகும்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                கல்வி, மேன்மையோடு பெருகும். அறிவு
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                பணிபுரிவோர்க்கு நற்பதவி, உயர்வு தந்து மேன்மை அடையச் செய்வாள்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                வியாபாரம் வளர்ந்து நியாயமான இலாபம் கிடைக்கும்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                வேலை யின்றி வேதனைப்படுவோர்க்கு வேலைவாய்ப்பு கிட்டும்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                விவசாயத்தில்வளம் பெருகும்; பக்தியிலே இணைந்து ஆன்மிகத்தில்ஈடுபாடு
                உண்டாகும்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                நம்மைத் தீயவரிடம் இருந்து விலக்கி, நாம் நல் ஒழுக்கத்தோடு வாழ
                அன்னை வழி வகுப்பாள்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                மற்றும் பக்தர்கள் மனமுருக மருவத்தூராளை நினைத்து கோருகின்ற எந்த
                நல்ல வேண்டுகோளும் ஈடேறும்.
              </Text>
            </li>
          </div>
        </ul>
        <div style={{ marginTop: "10px" }}>
          <Text
            style={{
              color: "black",
              textAlign: "justify",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            அணியும் முறை (பொது)
          </Text>
        </div>
        <ul>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                சக்திமாலையைச் சிறுபிள்ளைகள் உட்பட ஆண், பெண் அனைவரும் வயது வரம்பு
                இன்றி அணியலாம். சக்தி மாலையை அணிந்து பக்தர்கள் தாம் இருக்கும்
                இடத்திலேயே{" "}
                <Text
                  style={{ textDecoration: "underline", fontWeight: "bold" }}
                >
                  {"ஐந்து அல்லது மூன்று நாட்கள்"}
                </Text>{" "}
                விரதம்மேற்கொண்டு மேல்மருவத்தூர் சித்தர் பீடத்தினை அடைய வேண்டும்.
                உடல்நலமின்றி வாடுபவர்கள் நோய் நீங்கி நலம் பெறுவார்கள்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                மாலை அணிபவர்கள் (பணிக்குச் செல்பவர்கள் உட்பட) , அந்தந்தமாலை
                எண்ணிக்கைக்கு உரிய சிவப்பு அல்லது மஞ்சள் நிற உடைகள் மட்டுமே அணிய
                வேண்டும்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                ஆடவருக்கு ஆண்குருவும், மகளிர்க்குப் பெண்குருவும் தான் மாலை
                அணிவிக்க வேண்டும்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                விரத நாட்களில் மாலை அணிந்தவர்கள், ஒரு வேளை உணவைத் தவிர்க்க
                வேண்டும்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                விரதநாட்களில் ஐம்புலன்களை அடக்கி. மனக்கட்டுப்பாட்டுடன் தீய
                பழக்கங்களைத் தவிர்த்துத் தங்களுடைய கடமைகளைச் செய்து கொண்டு
                அன்னையின் திருநாமத்தை நினைவில் எப்பொழுதும் இருத்தி வழிபடுதல்
                வேண்டும்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                உறங்கும் போதும் செவ்வாடையின் மீதே படுத்து உறங்க வேண்டும்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                காலை மாலை இருவேளையும் நீராடி அன்னையை வழிபடுதல் வேண்டும்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                காலையில் தம் வீட்டில் உள்ள அன்னையின் திருவுருவப் படத்தின் முன்
                அம்மாவின் திருமந்திரப் பாடல்களைப் பாடி வழிபடுதல் அவசியம்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                மாலை வேளைகளில் தவறாமல் மாலை அணிந்தவர்கள் தாங்கள் வசிக்கும்
                பகுதியிலுள்ள ஆதிபராசக்தி வார வழிபாட்டு மன்றத்திற்கோ அல்லது
                அம்மன் ஆலயத்திற்கோ சென்று கூட்டு வழிபாடு செய்தல் வேண்டும்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                மாலை அணிந்தவர்கள் ஒருவரையொருவர் சந்திக்கும் பொழுது முதலில் 'ஓம்
                சக்தி' என்று சொல்லுதல் வேண்டும்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                மாலை அணிபவர்கள் தங்களின் இருப்பிடத்திற்கு அருகிலுள்ள வார
                வழிபாட்டு மன்றத்திலோ அல்லது தாங்கள் சார்ந்துள்ள மன்றத்திலோ தான்
                மாலை அணிய வேண்டும். அருகில் மன்றங்கள் இல்லை எனில், அருகிலுள்ள
                அம்மன் ஆலயத்தில் அன்னையின் திருவுருவப் படத்தினை வைத்து முறைப்படி
                வழிபாடு செய்து மாலை அணியவேண்டும்.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                மாலை அணிந்து விரதமிருந்து சித்தர் பீடம் வந்து திரும்பப்
                பெண்களுக்கு மாதவிலக்கு ஒரு தடையல்ல.
              </Text>
            </li>
          </div>
          <div style={{ marginTop: "10px" }}>
            <li>
              <Text
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                சக்தி மாலை அணிய விரும்புவோர்கள் இருமுடிப் பை, சக்தி மாலை.
                ஆகியவற்றினைச் பீடத்திலிருந்துதான் டாலர் சித்தர் உரிய கட்டணம்
                செலுத்திப் பெற வேண்டும். அல்லது சித்தர் பீடத்திலிருந்து
                மேற்கூறியவற்றினை வாங்கி வைத்திருக்கும் மன்றங்கள் மூலம்பெற்றுக்
                கொள்ளலாம்.
              </Text>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};
export default SakthiMaalai;
